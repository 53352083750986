import React from "react";
import { useTranslation } from "react-i18next";
import { CheckboxIcon, CrossIcon } from "@aureskonnect/react-ui";
import { AvForm } from "availity-reactstrap-validation";
import { Modal } from "reactstrap";
import { toast } from "react-toastify";
import { useSnapshot } from "valtio";
import { mutate } from "swr";

import { store } from "@components/VerticalLayout/store";
import { setIsEdited } from "../store";
import {
    CustomCardFooter,
    CustomCardHeader,
} from "../../../../components/Common/CustomCardStyled";
import { capitalize } from "../../../../helpers/general";
import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";
import AggregatorBody from "../AggregatorFranchise/AggregatorBody";

import "@assets/swal.css";
import "@assets/theme.css";
import "react-toastify/dist/ReactToastify.css";
import "./index.css";

type AggregatorFormType = {
    isModalOpened: boolean;
    setIsModalOpened: Function;
    rowData: any;
    setDataIsUpdated: Function;
};

export default function AggregatorConfigure({
    isModalOpened,
    setIsModalOpened,
    rowData,
    setDataIsUpdated,
}: AggregatorFormType) {
    const { t } = useTranslation();
    const { userID, operatorID, franchiseID } = useSnapshot(store);
    const uuidUser = localStorage.getItem("uuidUser");

    const [isInvalidWebhook, setIsInvalidWebhook] = React.useState<boolean>(
        false
    );
    const [webhook, setWebhook] = React.useState<string>(rowData[t("Webhook")]);
    const [deliverooIdentifier, setDeliverooIdentifier] = React.useState<
        string
    >(rowData.deliverooIdentifier);
    const [choiceAggregator, setChoiceAggregator] = React.useState<string>(
        rowData[t("Aggregator")]
    );
    const [selectChoiceAggregator, setSelectChoiceAggregator] = React.useState<
        boolean
    >(false);
    const [remark, setRemark] = React.useState<string>(rowData[t("Remark")]);

    async function handleValidate() {
        if (!isInvalidWebhook) {
            toast.dismiss();
            const apiUrlUpdate = `${process.env.REACT_APP_API_V2_URL}/settings/general/aggregator`;
            delete rowData.Webhook;
            let dataConfig = {
                userId: userID,
                shopId: [rowData.shopId],
                franchiseId: franchiseID,
                operatorId: operatorID,
                uuidUser: uuidUser,
                data: {
                    [rowData.Id]: {
                        ...rowData,
                        aggregator: rowData[t("Aggregator")],
                        remark: remark,
                        webhook: webhook,
                        deliverooIdentifier: deliverooIdentifier,
                    },
                },
            };

            const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(dataConfig),
            };
            try {
                mutate(
                    apiUrlUpdate,
                    await fetch(apiUrlUpdate, requestOptions)
                        .then((response) => response.json())
                        .then((data) => {
                            if (data.error) {
                                throw Error("error");
                            }
                            toast.success(
                                `${t(
                                    "The Aggregator setting has been successfully modified"
                                )!}`,
                                {
                                    position: toast.POSITION.TOP_CENTER,
                                    theme: "colored",
                                    icon: (
                                        <CheckboxIcon
                                            height={25}
                                            width={25}
                                            fill="white"
                                        />
                                    ),
                                }
                            );
                            setIsModalOpened(false);
                            setDataIsUpdated!(true);
                        })
                );
            } catch (e) {
                toast.error(`${t("There's an error")!}`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    theme: "colored",
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
        }
    }

    return (
        <React.Fragment>
            <Modal
                centered
                isOpen={isModalOpened}
                className="information_fidelity_modal__clz mt-5"
            >
                <AvForm className="card information_fidelity__clz">
                    <CustomCardHeader className="d-flex align-items-center justify-content-between">
                        <h5 className="pt-3">
                            {capitalize(t("Aggregator setting"))}
                        </h5>
                        <CrossIcon
                            className="icon-close-notification__clz"
                            height={13}
                            width={13}
                            onClick={() => {
                                setIsModalOpened(false);
                            }}
                        />
                    </CustomCardHeader>
                    <AggregatorBody
                        isConsult={false}
                        choiceAggregator={choiceAggregator}
                        selectChoiceAggregator={selectChoiceAggregator}
                        isExist={false}
                        dataAggregator={rowData}
                        setChoiceAggregator={setChoiceAggregator}
                        setSelectChoiceAggregator={setSelectChoiceAggregator}
                        setIsInvalidWebhook={setIsInvalidWebhook}
                        setWebhook={setWebhook}
                        isInvalidWebhook={isInvalidWebhook}
                        webhook={webhook}
                        deliverooIdentifier={deliverooIdentifier}
                        setDeliverooIdentifier={setDeliverooIdentifier}
                        setRemark={setRemark}
                        remark={remark}
                        isConfig={true}
                    />
                    <CustomCardFooter className="pt-1 pb-2 mt-4">
                        <CustomSecondaryColorButton
                            outline
                            rounded
                            className="mr-2 fed_btn_FRcancel cmn_btn_cancel"
                            variant="light"
                            onClick={() => {
                                setIsModalOpened(false);
                                setIsEdited(false);
                            }}
                        >
                            <span> {t("Cancel")}</span>
                        </CustomSecondaryColorButton>
                        <CustomMainColorButton
                            variant="primary"
                            onClick={() => {
                                handleValidate();
                                setIsEdited(false);
                            }}
                            rounded
                        >
                            {t("Validate")}
                        </CustomMainColorButton>
                    </CustomCardFooter>
                </AvForm>
            </Modal>
        </React.Fragment>
    );
}
