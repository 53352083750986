import React from "react";
import { useSnapshot } from "valtio";
import { useTranslation } from "react-i18next/";
import ErrorToast from "@components/Common/ErrorTost";
import classnames from "classnames";
import { mutate } from "swr";
import { toast } from "react-toastify";
import { CheckboxIcon, VerticalDotsIcon } from "@aureskonnect/react-ui";

import store from "../store";
import { store as fidelity } from "../../../../components/VerticalLayout/store";

import { ConfirmationArchive } from "../ConfirmationArchive";

type ActionColumnType = {
    selectedRows: any;
    setDataIsUpdated: Function;
    setIsFidelityArchived: Function;
    isFidelityArchived: boolean;
    setLimit: Function;
};
export function ActionColumn({
    selectedRows,
    setDataIsUpdated,
    setIsFidelityArchived,
    isFidelityArchived,
    setLimit,
}: ActionColumnType) {
    const { t } = useTranslation();
    const {
        archivedFidelityFranchise,
        shopsFidelityData,
        modify,
        numberActiveMultiShop,
        numberArchiveMultiShop,
        isSupportCodeValid,
        fidelityData,
    } = useSnapshot(store);
    const { franchiseID, userID, operatorID } = useSnapshot(fidelity);

    const [error, setError] = React.useState(false);
    const [isFidelityClicked, setIsFidelityClicked] = React.useState<boolean>(
        false
    );
    const [isArchiveModalOpened, setIsArchiveModalOpened] = React.useState<
        boolean
    >(false);
    const uuidUser = localStorage.getItem("uuidUser");

    function archiveFidelityFranchise() {
        let idsAssociated: any = [];
        if (
            archivedFidelityFranchise.othersData.archivedFranchise.data !==
            undefined
        ) {
            archivedFidelityFranchise.othersData.archivedFranchise.data.forEach(
                (element: any) => {
                    idsAssociated.push(element.Id);
                }
            );
        }
        idsAssociated = [...new Set(idsAssociated)];
        return idsAssociated;
    }

    const getArrayOfRows = () => {
        let arrayOfRows: any[] = [];
        let ids: any[] = [];

        selectedRows.forEach(async (el: any) => {
            if (
                el[t("Shop")] === undefined &&
                ids.indexOf(
                    el.fidelityChoiceIndex !== 1
                        ? `${el.shopId}${el.Id}`
                        : `${el.affectedShop}${el.Id}`
                ) === -1
            ) {
                arrayOfRows.push(el);
                ids.push(
                    el.fidelityChoiceIndex !== 1
                        ? `${el.shopId}${el.Id}`
                        : `${el.affectedShop}${el.Id}`
                );
            }
        });
        arrayOfRows = [...new Set(arrayOfRows)];
        return arrayOfRows;
    };

    let arrayOfRows = getArrayOfRows();

    function archiveFidelity() {
        let idsAssociated: any = [];
        if (fidelityData.data !== undefined) {
            fidelityData.data.forEach((element: any) => {
                idsAssociated.push(element.Id);
            });
        }
        idsAssociated = [...new Set(idsAssociated)];
        return idsAssociated;
    }
    let dataArchive: any[] = [];
    dataArchive = archiveFidelity();
    function shopsIdArchiveFidelity(e: any) {
        let idsAssociated: any = [];
        let shopSelectedRows: any[] = [];
        selectedRows.forEach((el: any) => {
            let isSameShop: boolean =
                el.fidelityChoiceIndex !== 1
                    ? el.shopId === e.shopId
                    : el.affectedShop === e.affectedShop;

            if (isSameShop) {
                shopSelectedRows.push(
                    el.fidelityChoiceIndex !== 1 ? el.shopId : e.affectedShop
                );
            }
        });

        if (shopsFidelityData.othersData.allFidelity.data !== undefined) {
            shopsFidelityData.othersData.allFidelity.data.forEach(
                (element: any) => {
                    if (
                        element.subRows !== undefined &&
                        element.subRows.length === 0 &&
                        ((element.archivedFidelity !== undefined &&
                            element.archivedFidelity.length === 1) ||
                            (selectedRows.subRows === undefined &&
                                shopSelectedRows.length === 1))
                    ) {
                        element.archivedFidelity.forEach((el: any) => {
                            if (el.Id === e.Id && el.shopId === e.shopId)
                                idsAssociated.push(el.shopId);
                        });
                    }
                }
            );
        }

        idsAssociated = [...new Set(idsAssociated)];
        return idsAssociated;
    }

    async function activeFidelity() {
        toast.dismiss();
        let localData: any = [];
        let arrayOfRows = getArrayOfRows();
        let idsShops = arrayOfRows
            .filter((el) => el.fidelityChoiceIndex === 1)
            .map((el) => el.affectedShop);
        arrayOfRows.forEach(async (el: any) => {
            if (el.fidelityChoiceIndex === 1) {
                el.isArchived.forEach((shop: any) => {
                    if (idsShops.includes(shop.shopId)) {
                        shop.status = false;
                    }
                });
            }
        });

        arrayOfRows.forEach(async (el: any) => {
            let shopsIdArchived = shopsIdArchiveFidelity(el);
            let dataFidelity = {
                userId: userID,
                shopId: shopsIdArchived,
                franchiseId: franchiseID,
                operatorId: operatorID,
                uuidUser: uuidUser,
                isShop: false,
                data: {
                    [el.Id]: {
                        designation: el[t("Display name")],
                        type: el[t("Loyalty")],
                        number_fidelity_points: el.number_fidelity_points,
                        money_correspondence: el.moneyCorrespondence,
                        devise: el.Devise,
                        pays: el.Pays,
                        authentication_mode: el.authentication_mode,
                        sortedVisibility: el.sortedVisibility,
                        Login: el.Login,
                        password: el[t("Password")],
                        note: el[t("Remark")],
                        isActive: false,
                        isArchived:
                            el.fidelityChoiceIndex !== 1
                                ? false
                                : JSON.stringify(el.isArchived),
                        shopId: el.shopId,
                        dashboardLink: el[t("Dashboard Link")],
                        user: el.user,
                        apiKey: el["API key"],
                        auresKonnectGroup: el[t("Group")],
                        isCentralizationActive: el[t("State")],
                        isVisibiliteActive: el[t("isVisibiliteActive")],

                        fidelityChoiceIndex: el.fidelityChoiceIndex,
                    },
                },
            };
            // if (dataFidelity.shopId.length !== 0) {
            //     isNotEmpty = true;
            // } else {
            //     isEmpty = true;
            // }

            const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/general/fidelity`;
            localData.push(
                fetch(apiUrlAdd, {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify(dataFidelity),
                }).then((response) => response.json())
            );
        });

        try {
            mutate(
                `${process.env.REACT_APP_API_V2_URL}/settings/general/fidelity`,
                await Promise.all(localData).then((result) => {})
            );
        } catch (e) {
            toast.error(`${t("There's an error")!}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
            setError(true);
        }

        if (error === false) {
            toast.success(
                `${t("The fidelity setting has been successfully unarchive")!}`,
                {
                    position: toast.POSITION.TOP_CENTER,
                    theme: "colored",
                    icon: <CheckboxIcon height={25} width={25} fill="white" />,
                }
            );

            setDataIsUpdated!(true);
        }
        setLimit(2);
    }
    const seen: any = {};
    let hasDuplicates = false;

    arrayOfRows
        .map((el: any) => {
            if (el.fidelityChoiceIndex === 1) {
                return el.affectedShop;
            } else {
                return el.shopId;
            }
        })
        .forEach((item: any) => {
            if (seen[item]) {
                hasDuplicates = true;
            }
            seen[item] = true;
        });

    return (
        <React.Fragment>
            <div>
                <div className="dropdown">
                    <VerticalDotsIcon
                        id="dropdownMenuButton1"
                        className="cmn_drp_BTmenu"
                        data-bs-toggle="dropdown"
                        style={{ cursor: "pointer" }}
                        height={25}
                        width={25}
                        fill="black"
                    />
                    <div
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton"
                    >
                        <div
                            className="dropdown-item pl-1"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                                setIsFidelityArchived(false);
                            }}
                        >
                            <span className="ml-2 cmn_btn_BTlistActivated">
                                {t("Fidelity Enabled(s)")} (
                                {numberActiveMultiShop})
                            </span>
                        </div>
                        <div
                            className="dropdown-item pl-1"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                                setIsFidelityArchived(true);
                            }}
                        >
                            <span className="ml-2 cmn_btn_BTlistArchived">
                                {t("Archived fidelity(s)")}(
                                {numberArchiveMultiShop})
                            </span>
                        </div>
                        {modify === true ? (
                            <>
                                <div
                                    style={{ cursor: "pointer" }}
                                    className={`${classnames(
                                        " dropdown-item pl-1 pointer__clz",
                                        {
                                            "not-allowed-icon__clz":
                                                selectedRows.length === 0 ||
                                                (isFidelityArchived &&
                                                    getArrayOfRows().some(
                                                        (element: any) =>
                                                            archiveFidelityFranchise().includes(
                                                                element.Id
                                                            )
                                                    )) ||
                                                (isFidelityArchived &&
                                                    arrayOfRows.some(
                                                        (element: any) =>
                                                            dataArchive.includes(
                                                                element.Id
                                                            )
                                                    )) ||
                                                arrayOfRows.length === 0 ||
                                                getArrayOfRows().every(
                                                    (element: any) =>
                                                        element[t("State")] ===
                                                        true
                                                ) ||
                                                (isFidelityArchived &&
                                                    hasDuplicates),
                                        }
                                    )}`}
                                    onClick={() => {
                                        if (!isFidelityArchived) {
                                            ErrorToast(
                                                `${t(
                                                    "Please note, loyalty archiving is done exclusively by the franchise."
                                                )!}`
                                            );
                                        } else {
                                            setIsFidelityClicked(true);
                                            if (arrayOfRows.length < 2) {
                                                ErrorToast(
                                                    `${t(
                                                        "Attention, it is a multiple action. Please select at least two items"
                                                    )!}`
                                                );
                                            } else {
                                                activeFidelity();
                                            }
                                        }
                                    }}
                                >
                                    <span className="ml-2 cmn_btn_BTarchived cmn_btn_BTunarchive">
                                        {isFidelityArchived
                                            ? t("unarchive")
                                            : t("archive")}
                                    </span>
                                </div>
                            </>
                        ) : null}
                    </div>
                </div>
            </div>
            {isArchiveModalOpened ||
            (isFidelityClicked && isSupportCodeValid) ? (
                <ConfirmationArchive
                    isModalOpened={
                        isArchiveModalOpened ||
                        (isFidelityClicked && isSupportCodeValid)
                    }
                    setIsModalOpened={setIsArchiveModalOpened}
                    setDataIsUpdated={setDataIsUpdated}
                    selectedRows={selectedRows}
                    setLimit={setLimit}
                />
            ) : null}
        </React.Fragment>
    );
}
