import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { CrossIcon, CheckboxIcon } from "@aureskonnect/react-ui";
import { AvForm } from "availity-reactstrap-validation";
import { mutate } from "swr";
import { toast } from "react-toastify";
import { useSnapshot } from "valtio";
import { uuid } from "uuidv4";
import classnames from "classnames";

import { ConfirmationEditMessage } from "@components/Common/ConfirmationEditMessage";
import {
    CustomCardFooter,
    CustomCardHeader,
} from "@components/Common/CustomCardStyled";

import { store } from "@components/VerticalLayout/store";
import {
    store as aggregator,
    setNumberActive,
    setIsActionsClicked,
    setActions,
    setIsEdited,
    setIsArchived,
    setGlobalDataIsUpdated,
} from "../store";
import { clearData } from "@components/Common/MultiCustomCreatableSelection/store";
import { ConfirmationModificationMessage } from "./ConfirmationModificationMessage";
import { clearMultiCustomSelectionData } from "@components/Common/MultiCustomSelect/store";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";
import { ConfirmationAssociationModal } from "./ConfirmationAssociationModal";
import AggregatorBody from "./AggregatorBody";

import "@assets/swal.css";
import "@assets/theme.css";
import "react-toastify/dist/ReactToastify.css";
import "./index.css";

type AggregatorFormType = {
    setIsAddAggregatorClicked: Function;
    isAddAggregatorClicked: boolean;
    setDataIsUpdated: React.Dispatch<React.SetStateAction<boolean | number>>;
    dataAggregator: React.SetStateAction<any>;
    isAddNewAggregatorButtonClicked: boolean;
    setDataAggregator: Function;
    setLocalFilterActive: Function;
    isConsult: boolean;
    setIsConsult: Function;
    dataIsUpdated: number | boolean;
};

export default function AggregatorForm({
    setIsAddAggregatorClicked,
    isAddAggregatorClicked,
    setDataIsUpdated,
    dataAggregator,
    isAddNewAggregatorButtonClicked,
    setDataAggregator,
    setLocalFilterActive,
    setIsConsult,
    isConsult,
    dataIsUpdated,
}: AggregatorFormType) {
    const { t } = useTranslation();

    const {
        userID,
        franchiseID,
        shopID,
        oneShop,
        operatorID,
        shopName,
    } = useSnapshot(store);
    const {
        numberActive,
        shopsAggregatorData,
        aggregatorData,
        aggregatorFranchise,
        dataArchive,
        dataFranchiseArchive,
        isActionsClicked,
        isEdited,
    } = useSnapshot(aggregator);

    const [title, setTitle] = React.useState<string>("");

    const [isModalOpened, setIsModalOpened] = React.useState<boolean>(false);
    const [isModalOpen, setIsModalOpen] = React.useState(false);

    const [canValidate, setCanValidate] = React.useState(true);

    const shopId = oneShop ? shopID : "0";

    const [associated, setAssociated] = React.useState<any[]>([]);
    const [dataEdited, setDataEdited] = React.useState<any>();
    const [isExist, setIsExist] = React.useState<boolean>(false);
    const [isModalModifiedOpened, setIsModalModifiedOpened] = React.useState<
        boolean
    >(false);

    const [choiceAggregator, setChoiceAggregator] = React.useState<string>("");
    const [selectChoiceAggregator, setSelectChoiceAggregator] = React.useState<
        boolean
    >(false);

    const [remark, setRemark] = React.useState<string>("");
    const [webhook, setWebhook] = React.useState<string>("");
    const [deliverooIdentifier, setDeliverooIdentifier] = React.useState<any>(
        ""
    );

    const [isInvalidWebhook, setIsInvalidWebhook] = React.useState<boolean>(
        false
    );

    const [, setIsValidateButtonEnabled] = React.useState<boolean>(true);

    const uuidUser = localStorage.getItem("uuidUser");

    function checkIfAggregatorExist() {
        let isAggregatorExist = false;

        if (aggregatorData.data !== undefined && dataArchive !== undefined) {
            let allData: any[] = [...aggregatorData.data, ...dataArchive];

            allData.forEach((el: any) => {
                if (
                    el[t("aggregator")] === choiceAggregator ||
                    el[t("Aggregator")] === choiceAggregator
                )
                    isAggregatorExist = true;

                return;
            });
        }

        return isAggregatorExist;
    }
    async function RedisPublishPeripheral() {
        let redisApiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/peripheral/redis`;

        try {
            mutate(
                redisApiUrl,
                await fetch(redisApiUrl, {
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bareer ${localStorage.getItem("jwt")}`,
                    },
                    body: JSON.stringify({
                        shopId: shopID,
                    }),
                    method: "POST",
                })
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.error) {
                            throw Error("Error while saving selection!");
                        }
                    })
            );
        } catch (e: any) {
            console.log(`${t("There's an error")}!`);
        }
    }
    function checkIfAggregatorFranchiseExist() {
        let isAggregatorExist = false;
        if (aggregatorFranchise.othersData.allData.data !== undefined) {
            aggregatorFranchise.othersData.allData.data.forEach((el: any) => {
                if (el[t("Aggregator")] === choiceAggregator)
                    isAggregatorExist = true;
                return;
            });
        }
        return isAggregatorExist;
    }

    function checkIfAggregatorFranchiseExistAndArchive() {
        let isAggregatorExist = false;
        if (dataFranchiseArchive !== undefined)
            dataFranchiseArchive.forEach((el: any) => {
                if (el.aggregator === choiceAggregator)
                    isAggregatorExist = true;
                return;
            });

        return isAggregatorExist;
    }

    async function addAggregator() {
        let isAggregatorFranchiseExist = checkIfAggregatorFranchiseExist();
        let isAggregatorFranchiseExistAndArchived = checkIfAggregatorFranchiseExistAndArchive();

        const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/general/aggregator`;
        let data = {
            userId: userID,
            shopId: oneShop ? [shopId, "0"] : [shopId],
            franchiseId: franchiseID,
            operatorId: operatorID,
            uuidUser: uuidUser,
            adding: true,
            data: {
                [uuid()]: {
                    aggregator: choiceAggregator,
                    webhook: webhook,
                    remark: remark,
                    isArchived: false,
                    shopId: shopId,
                    state: dataAggregator.state,
                    stateShop: dataAggregator.stateShop,
                    deliverooIdentifier: deliverooIdentifier,
                },
            },
        };

        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data),
        };
        if (isAggregatorFranchiseExistAndArchived && oneShop) {
            setIsModalModifiedOpened(true);
            setIsArchived(true);
            setCanValidate(true);
        } else if (isAggregatorFranchiseExist && oneShop) {
            setIsModalModifiedOpened(true);
            setIsArchived(false);
            setCanValidate(true);
        } else {
            try {
                mutate(
                    apiUrlAdd,
                    await fetch(apiUrlAdd, requestOptions)
                        .then((response) => response.json())
                        .then((data) => {
                            if (data.error) {
                                setCanValidate(true);
                                setDataIsUpdated!(true);
                                throw Error("error");
                            }
                            setCanValidate(true);
                            toast.success(
                                `${t(
                                    "The Aggregator has been successfully added"
                                )!}`,
                                {
                                    position: toast.POSITION.TOP_CENTER,
                                    theme: "colored",
                                    icon: (
                                        <CheckboxIcon
                                            height={25}
                                            width={25}
                                            fill="white"
                                        />
                                    ),
                                }
                            );
                            if (oneShop) RedisPublishPeripheral();
                            setDataAggregator([]);
                            setIsEdited(false);
                            setLocalFilterActive(false);
                            setNumberActive(numberActive + 1);
                            setDataIsUpdated!(true);
                            setGlobalDataIsUpdated(true);
                            setChoiceAggregator("");

                            clearData();
                            clearMultiCustomSelectionData();
                        })
                );
            } catch (e) {
                setCanValidate(true);
                toast.error(`${t("There's an error")!}`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    theme: "colored",
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
        }
    }

    async function editAggregator() {
        let dataAssociate: any[] = [];

        toast.dismiss();
        const apiUrlUpdate = `${process.env.REACT_APP_API_V2_URL}/settings/general/aggregator`;
        console.log({ remark });

        let dataConfig = {
            userId: userID,
            shopId: [shopId],
            franchiseId: franchiseID,
            operatorId: operatorID,
            uuidUser: uuidUser,
            data: {
                [dataAggregator.Id]: {
                    aggregator: choiceAggregator,
                    webhook: webhook,
                    remark: remark,
                    isArchived: false,
                    shopId: shopId,
                    state: dataAggregator.state,
                    stateShop: dataAggregator.stateShop,
                    deliverooIdentifier: deliverooIdentifier,
                },
            },
        };
        setDataEdited(dataConfig);

        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(dataConfig),
        };

        try {
            mutate(
                apiUrlUpdate,
                await fetch(apiUrlUpdate, requestOptions)
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.error) {
                            throw Error("error");
                        }
                        if (!oneShop) {
                            dataAssociate = AssociateAggregator();
                        }
                        toast.success(
                            `${t(
                                dataAssociate.length > 1
                                    ? "The Aggregator of franchise was successfully changed"
                                    : "The Aggregator setting has been successfully modified"
                            )!}`,
                            {
                                position: toast.POSITION.TOP_CENTER,
                                theme: "colored",
                                icon: (
                                    <CheckboxIcon
                                        height={25}
                                        width={25}
                                        fill="white"
                                    />
                                ),
                            }
                        );
                        if (dataAssociate.length > 1) {
                            setIsModalOpen(true);
                            setCanValidate(true);
                        } else {
                            setIsAddAggregatorClicked(!isAddAggregatorClicked);
                            setIsEdited(false);
                        }
                        setDataIsUpdated(!dataIsUpdated);
                        setGlobalDataIsUpdated(true);
                    })
            );
        } catch (e) {
            setCanValidate(true);
            toast.error(`${t("There's an error")!}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    }

    function AssociateAggregator() {
        let idsAssociated: any = [];
        let associated: any[] = [dataAggregator];
        if (shopsAggregatorData.data !== undefined) {
            shopsAggregatorData.data.forEach((element: any) => {
                element.subRows.forEach((el: any) => {
                    if (el.Id === dataAggregator.Id) {
                        idsAssociated.push(el.shopId);
                        associated.push({ ...el });
                    }
                });
            });
        }
        idsAssociated = [...new Set(idsAssociated)];
        return associated;
    }

    function handleValidate() {
        setIsValidateButtonEnabled(false);

        if (choiceAggregator === "") {
            setSelectChoiceAggregator(true);
        }

        if (dataAggregator.Id !== undefined) {
            if (
                dataAggregator[t("Remark")] === remark &&
                dataAggregator[t("webhook")] === webhook
            ) {
                setIsModalOpened(true);
            } else {
                if (
                    (["", "TCPOS", "Cashpad"].indexOf(choiceAggregator) ===
                        -1 &&
                        oneShop === true &&
                        !isInvalidWebhook) ||
                    (oneShop === false && choiceAggregator !== "")
                ) {
                    setCanValidate(false);
                    editAggregator();
                }
            }
        } else if (choiceAggregator !== "" && !isExist) {
            if (
                (["", "TCPOS", "Cashpad"].indexOf(choiceAggregator) === -1 &&
                    oneShop === true &&
                    !isInvalidWebhook) ||
                (oneShop === false && choiceAggregator !== "")
            ) {
                setCanValidate(false);

                addAggregator();
            }
        }
    }

    useEffect(() => {
        if (dataAggregator.Id !== undefined) {
            setTitle(t(isConsult ? "View Aggregator" : "Modify Aggregator"));
            setChoiceAggregator(dataAggregator[t("Aggregator")]);
            setRemark(dataAggregator[t("Remark")]);
            setWebhook(dataAggregator[t("Webhook")]);
            setDeliverooIdentifier(dataAggregator[t("deliverooIdentifier")]);
        } else {
            setTitle(t("Add Aggregator"));
            setChoiceAggregator("");
            setSelectChoiceAggregator(false);
            setWebhook("");
            setRemark("");
            setDeliverooIdentifier(oneShop ? `${shopID}_${shopName}` : "");
        }

        setAssociated(AssociateAggregator);

        // eslint-disable-next-line
    }, [
        dataAggregator,
        t,
        isAddNewAggregatorButtonClicked,
        isAddAggregatorClicked,
    ]);

    useEffect(() => {
        clearData();
        clearMultiCustomSelectionData();
    }, [isAddAggregatorClicked, dataAggregator.Id]);

    useEffect(() => {
        let isAggregatorExist = checkIfAggregatorExist();
        if (
            isAggregatorExist &&
            (dataAggregator.Id === undefined ||
                (dataAggregator.Id !== undefined &&
                    dataAggregator[t("Aggregator")] !== choiceAggregator))
        ) {
            setIsExist(true);
        } else {
            setIsExist(false);
        }
        //eslint-disable-next-line
    }, [choiceAggregator, t, dataAggregator]);

    return (
        <React.Fragment>
            <AvForm className="card">
                <CustomCardHeader
                    className="d-flex align-items-center justify-content-between"
                    style={{ maxHeight: "76px" }}
                >
                    <h5
                        className={`${classnames("pt-3", {
                            fed_txt_FRtitleFormLoyalty:
                                dataAggregator.Id === undefined,
                        })}`}
                    >
                        {title}
                    </h5>
                    <CrossIcon
                        className="agr_icn_close"
                        height={13}
                        width={13}
                        onClick={() => {
                            setIsConsult(false);
                            if (isEdited === true) {
                                setIsActionsClicked(!isActionsClicked);
                                setActions(() => {
                                    setIsEdited(false);
                                    setDataAggregator([]);
                                    setIsAddAggregatorClicked!(
                                        !isAddAggregatorClicked
                                    );
                                    setIsEdited(false);
                                    setDataIsUpdated!(true);
                                });
                            } else {
                                setDataAggregator([]);
                                setIsAddAggregatorClicked!(
                                    !isAddAggregatorClicked
                                );
                                setIsEdited(false);
                                setDataIsUpdated!(true);
                            }
                        }}
                    />
                </CustomCardHeader>
                <AggregatorBody
                    isConsult={isConsult}
                    choiceAggregator={choiceAggregator}
                    selectChoiceAggregator={selectChoiceAggregator}
                    isExist={isExist}
                    dataAggregator={dataAggregator}
                    setChoiceAggregator={setChoiceAggregator}
                    setSelectChoiceAggregator={setSelectChoiceAggregator}
                    setIsInvalidWebhook={setIsInvalidWebhook}
                    setWebhook={setWebhook}
                    isInvalidWebhook={isInvalidWebhook}
                    webhook={webhook}
                    deliverooIdentifier={deliverooIdentifier}
                    setDeliverooIdentifier={setDeliverooIdentifier}
                    setRemark={setRemark}
                    remark={remark}
                    isConfig={false}
                />
                <CustomCardFooter className="pt-1 pb-2">
                    <CustomSecondaryColorButton
                        outline
                        rounded
                        className="mr-2 cmn_btn_cancel"
                        variant="light"
                        onClick={() => {
                            if (isEdited === true) {
                                setIsActionsClicked(!isActionsClicked);
                                setActions(() => {
                                    setIsEdited(false);
                                    setIsAddAggregatorClicked(
                                        !isAddAggregatorClicked
                                    );
                                    clearMultiCustomSelectionData();
                                    clearData();
                                    setDataAggregator([]);
                                    setIsEdited(false);
                                    setDataIsUpdated!(true);
                                });
                            } else {
                                setIsAddAggregatorClicked(
                                    !isAddAggregatorClicked
                                );
                                clearMultiCustomSelectionData();
                                clearData();
                                setDataAggregator([]);
                                setIsEdited(false);
                                setDataIsUpdated!(true);
                            }
                            setIsConsult(false);
                        }}
                    >
                        <span> {t("Cancel")}</span>
                    </CustomSecondaryColorButton>
                    <CustomMainColorButton
                        rounded
                        variant="primary"
                        className={`fed_btn_FRvalidate cmn_btn_validate ${classnames(
                            { "not-allowed-input-vr__clz": isConsult }
                        )}`}
                        disabled={!canValidate}
                        onClick={() => {
                            handleValidate();
                        }}
                    >
                        {t("Validate")}
                    </CustomMainColorButton>
                </CustomCardFooter>
            </AvForm>
            <ConfirmationEditMessage
                setDataIsUpdated={setDataIsUpdated}
                isModalOpened={isModalOpened}
                setIsModalOpened={setIsModalOpened}
                isAddClicked={isAddAggregatorClicked}
                setIsAddClicked={setIsAddAggregatorClicked}
                setEditedData={setDataAggregator}
            />
            {isModalOpen && (
                <ConfirmationModificationMessage
                    isModalOpened={isModalOpen}
                    setIsModalOpened={setIsModalOpen}
                    setIsEdited={setIsEdited}
                    setDataIsUpdated={setDataIsUpdated}
                    dataAggregator={dataEdited}
                    associated={associated}
                    setIsAddFiscalYearClicked={setIsAddAggregatorClicked}
                    isAddFiscalYearClicked={isAddAggregatorClicked}
                    setCanValidate={setCanValidate}
                />
            )}

            {isModalModifiedOpened && (
                <ConfirmationAssociationModal
                    isModalOpened={isModalModifiedOpened}
                    setIsModalOpened={setIsModalModifiedOpened}
                    setDataIsUpdated={setDataIsUpdated}
                    data={{
                        choiceAggregator: choiceAggregator,
                    }}
                    PosData={{
                        aggregator: choiceAggregator,
                        remark: remark,
                        isArchived: false,
                        shopId: shopId,
                        state: dataAggregator.state,
                        stateShop: dataAggregator.stateShop,
                        webhook: webhook,
                        deliverooIdentifier: deliverooIdentifier,
                    }}
                    setChoiceAggregator={setChoiceAggregator}
                    setWebhook={setWebhook}
                    setRemark={setRemark}
                    setIsEdited={setIsEdited}
                />
            )}
        </React.Fragment>
    );
}
