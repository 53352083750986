import React from "react";
import Switch from "react-switch";
import { Card } from "reactstrap";
import { useTranslation } from "react-i18next/";
import { useSnapshot } from "valtio";
import classNames from "classnames";

import SecondImageModel from "../../../image/SecondImageModel.png";

import {
    setSecondModelSingleLabel,
    setSecondModelOnlineOrder,
    setSecondModelMenuComponent,
    setSecondModelNameMenu,
    resetStore,
    storeModelTicket,
} from "./store";
import { setCheckboxIcon, store } from "../store";

import { PrinterFooter } from "../PrinterFooter";

import "./index.css";

type SecondModelType = {
    rowData: any;
    oneShop: boolean;
    setCheckboxIconSecondModel: Function;
};

export default function SecondModel({
    rowData,
    setCheckboxIconSecondModel,
}: SecondModelType) {
    const { t } = useTranslation();
    const {
        secondModelSingleLabel,
        secondModelOnlineOrder,
        secondModelMenuComponent,
        secondModelNameMenu,
    } = useSnapshot(storeModelTicket);
    const { checkboxIconSecondModel, checkboxIcon } = useSnapshot(store);

    return (
        <React.Fragment>
            <div className="card_modale__clz">
                <div
                    className="card card-model-second__clz"
                    style={{ fontSize: "17px" }}
                >
                    <div className="p-3">
                        <span className="style-span__clz">
                            {t("Printing components")}
                        </span>
                    </div>
                    <div className="p-2 d-flex justify-content-between">
                        <span>{t("Print the menu on a single label")}</span>
                        <Switch
                            offColor="#faafb4"
                            offHandleColor="#E30613"
                            onColor="#c2eec4"
                            onHandleColor="#34C38F"
                            height={19}
                            handleDiameter={24}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            checked={secondModelSingleLabel}
                            onChange={(e: any) => {
                                setSecondModelSingleLabel(
                                    !secondModelSingleLabel
                                );
                            }}
                            className={classNames("", {
                                "not-allowed-icon__clz":
                                    rowData.tickets.label !== "Sticker",
                            })}
                        />
                    </div>
                    <div className="p-2 d-flex justify-content-between">
                        <span>
                            {t(
                                "Print the entire composition of a menu of one online order"
                            )}
                        </span>
                        <Switch
                            offColor="#faafb4"
                            offHandleColor="#E30613"
                            onColor="#c2eec4"
                            onHandleColor="#34C38F"
                            height={19}
                            handleDiameter={24}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            checked={secondModelOnlineOrder}
                            onChange={(e: any) => {
                                setSecondModelOnlineOrder(
                                    !secondModelOnlineOrder
                                );
                            }}
                        />
                    </div>
                    <div className="p-2 d-flex justify-content-between">
                        <span>
                            {t("Print [Menu] in front of a menu component")}
                        </span>
                        <Switch
                            offColor="#faafb4"
                            offHandleColor="#E30613"
                            onColor="#c2eec4"
                            onHandleColor="#34C38F"
                            height={19}
                            handleDiameter={24}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            checked={secondModelMenuComponent}
                            onChange={(e: any) => {
                                setSecondModelMenuComponent(
                                    !secondModelMenuComponent
                                );
                            }}
                            className="not-allowed-icon__clz"
                        />
                    </div>
                    <div className="p-2 d-flex justify-content-between">
                        <span> {t("Print [Menu] by the name of a menu")}</span>
                        <Switch
                            offColor="#faafb4"
                            offHandleColor="#E30613"
                            onColor="#c2eec4"
                            onHandleColor="#34C38F"
                            height={19}
                            handleDiameter={24}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            checked={secondModelNameMenu}
                            onChange={(e: any) => {
                                setSecondModelNameMenu(!secondModelNameMenu);
                            }}
                            className="not-allowed-icon__clz"
                        />
                    </div>
                </div>
                <div className="card-model-first__clz">
                    <div>
                        <input
                            className={classNames("check-style__clz", {
                                "not-allowed-icon__clz": checkboxIconSecondModel,
                            })}
                            style={{
                                marginBottom: "530px",
                                marginRight: "122px",
                            }}
                            type="checkbox"
                            checked={checkboxIconSecondModel}
                            onChange={() => {
                                setCheckboxIcon(!checkboxIcon);
                                setCheckboxIconSecondModel(
                                    !checkboxIconSecondModel
                                );
                            }}
                        />
                    </div>
                    <Card>
                        <img
                            src={SecondImageModel}
                            alt="Logo"
                            className="style-logo__clz"
                        />
                    </Card>
                </div>
            </div>
            <PrinterFooter rowData={rowData} resetStore={resetStore} />
        </React.Fragment>
    );
}
