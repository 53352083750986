import React from "react";
import classnames from "classnames";
import { toast } from "react-toastify";
import {
    CheckboxIcon,
    EyeIcon,
    SettingsIcon,
    TrashIcon,
} from "@aureskonnect/react-ui";
import { useSnapshot } from "valtio";
import { useTranslation } from "react-i18next";
import { mutate } from "swr";
import { Tooltip } from "@mui/material";
import ReactSwitch from "react-switch";
import { uuid } from "uuidv4";

import { store as aggregator } from "../../../../components/VerticalLayout/store";
import store, {
    setKey,
    setNumberActiveMultiShop,
    setNumberArchiveMultiShop,
    setSelectedAggregator,
    setSelectedRowsAggregator,
} from "../store";
import {
    setActivationDeactivationMessageModalOpened,
    setMessage,
    setYesFunction,
} from "@components/Common/ConfirmationActivationDeactivationMessageModal/store";

import TimePastSvgIcon from "@components/Common/SvgIcons/TimePastSvgIcon";
import { ConfirmationArchive } from "../ConfirmationArchive";

type customIconType = {
    e: any;
    setDataIsUpdated: Function;
    setIsHistoryModalOpened: Function;
    setSelectedRows: Function;
    isAggregatorArchived: boolean;
    selectedRows: any;
    setIsConfigureModalOpened: Function;
};

export function CustomIcons({
    e,
    setDataIsUpdated,
    setIsHistoryModalOpened,
    setSelectedRows,
    isAggregatorArchived,
    selectedRows,
    setIsConfigureModalOpened,
}: customIconType) {
    const { t } = useTranslation();
    const {
        numberActiveMultiShop,
        numberArchiveMultiShop,
        aggregatorData,
        modify,
    } = useSnapshot(store);
    const { franchiseID, userID, oneShop, operatorID } = useSnapshot(
        aggregator
    );
    const [loading, setLoading] = React.useState<boolean>(false);
    const [aggregatorState, setAggregatorState] = React.useState<boolean>(
        e.selectedRow.state
    );
    const [isArchiveModalOpened, setIsArchiveModalOpened] = React.useState<
        boolean
    >(false);
    const uuidUser = localStorage.getItem("uuidUser");

    let dataArchive: any[] = [];

    if (!oneShop) {
        dataArchive = archiveAggregator();
    }
    function archiveAggregator() {
        let idsAssociated: any = [];
        if (aggregatorData.data !== undefined) {
            aggregatorData.data.forEach((element: any) => {
                idsAssociated.push(element.Id);
            });
        }
        idsAssociated = [...new Set(idsAssociated)];
        return idsAssociated;
    }

    async function activeAggregator() {
        toast.dismiss();
        setLoading(true);
        let dataAggregator = {
            userId: userID,
            shopId: [e.selectedRow.shopId],
            franchiseId: franchiseID,
            operatorId: operatorID,
            uuidUser: uuidUser,
            isShop: true,
            data: {
                [e.selectedRow.Id]: {
                    aggregator: e.selectedRow[t("Aggregator")],
                    webhook: e.selectedRow[t("Webhook")],
                    remark: e.selectedRow[t("Remark")],
                    isActive: false,
                    isArchived: false,
                    shopId: e.selectedRow.shopId,
                    state: e.selectedRow.state,
                    stateShop: e.selectedRow.stateShop,
                    deliverooIdentifier: e.selectedRow.deliverooIdentifier,
                },
            },
        };

        const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/general/aggregator`;
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(dataAggregator),
        };
        try {
            mutate(
                apiUrlAdd,
                await fetch(apiUrlAdd, requestOptions)
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.error) {
                            setLoading(false);
                            throw Error("error");
                        }
                        toast.success(
                            `${t(
                                "The Aggregator has been successfully unarchive"
                            )!}`,
                            {
                                position: toast.POSITION.TOP_CENTER,
                                theme: "colored",
                                icon: (
                                    <CheckboxIcon
                                        height={25}
                                        width={25}
                                        fill="white"
                                    />
                                ),
                            }
                        );
                        setDataIsUpdated!(true);
                        setLoading(false);
                        setKey(uuid());
                    })
            );
        } catch (e: any) {
            setLoading(false);
            toast.error(`${t("There's an error")!}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    }

    async function handleStateChange() {
        let id = e.selectedRow.Id;
        setLoading(true);
        if (id !== undefined) {
            let dataConfig = [
                {
                    userId: userID,
                    operatorId: operatorID,
                    uuidUser: uuidUser,
                    shopId: [e.selectedRow.shopId],
                    franchiseId: franchiseID,
                    isShop: false,
                    data: {
                        [id]: {
                            aggregator: e.selectedRow[t("Aggregator")],
                            webhook: e.selectedRow[t("Webhook")],
                            remark: e.selectedRow[t("Remark")],
                            isActive: !aggregatorState,
                            state: !aggregatorState,
                            stateShop: !aggregatorState,
                            isArchived: false,
                            shopId: e.selectedRow.shopId,
                            deliverooIdentifier:
                                e.selectedRow.deliverooIdentifier,
                        },
                    },
                },
            ];
            const isStateChange = true;
            let apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/general/aggregator`;
            mutate(
                apiUrl,
                await fetch(apiUrl, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ dataConfig, isStateChange }),
                    method: "POST",
                })
                    .then((response) => response.json())
                    .then((result) => {
                        if (result.error) {
                            setLoading(false);
                            throw Error(result.message);
                        }

                        toast.success(
                            aggregatorState === true
                                ? `${t(
                                      "The Aggregator has been successfully deactivated"
                                  )}`
                                : `${t(
                                      "The Aggregator has been successfully activated"
                                  )}`,
                            {
                                position: toast.POSITION.TOP_CENTER,
                                autoClose: 2000,
                                theme: "colored",
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                icon: (
                                    <CheckboxIcon
                                        height={25}
                                        width={25}
                                        fill="white"
                                    />
                                ),
                            }
                        );
                        setDataIsUpdated!(true);
                        setLoading(false);
                    })
                    .catch((error: any) => {
                        setLoading(false);
                        toast.error(`${t("There's an error")!}`, {
                            position: toast.POSITION.TOP_CENTER,
                            autoClose: 2000,
                            theme: "colored",
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                        });
                    })
            );
        }
    }
    return (
        <React.Fragment>
            {e.selectedRow?.subRows === undefined ? (
                <div className="d-flex justify-content-around">
                    {modify === true ? (
                        <>
                            <Tooltip
                                title={
                                    isAggregatorArchived
                                        ? ""
                                        : aggregatorState === false
                                        ? t("Activate")
                                        : t("Unactivate")
                                }
                            >
                                <div className="mt-2 mr-1">
                                    <ReactSwitch
                                        uncheckedIcon={false}
                                        checkedIcon={false}
                                        handleDiameter={26}
                                        offColor="#f7b4b8"
                                        offHandleColor="#E30613"
                                        checked={aggregatorState}
                                        onChange={async () => {
                                            setMessage(
                                                aggregatorState
                                                    ? t(
                                                          "Caution, the aggregator may be used by other modules.\n Would you like to confirm the deactivation of the selected aggregator?"
                                                      )
                                                    : t(
                                                          "Would you like to confirm the activation of the selected aggregator?"
                                                      )
                                            );
                                            setYesFunction(() => {
                                                setAggregatorState(
                                                    !aggregatorState
                                                );
                                                handleStateChange();
                                            });
                                            setActivationDeactivationMessageModalOpened(
                                                true
                                            );
                                        }}
                                        onColor="#c2eddd"
                                        onHandleColor="#34C38F"
                                        width={50}
                                        height={20}
                                        className={`${classnames(
                                            "pointer__clz ml-1 cmn_icn_BTenableDisable",
                                            {
                                                "not-allowed-icon__clz": isAggregatorArchived,
                                            }
                                        )}`}
                                    />
                                </div>
                            </Tooltip>

                            {!isAggregatorArchived ? (
                                <Tooltip
                                    title={
                                        e.selectedRow[t("State")] === true
                                            ? ""
                                            : t("Archive")
                                    }
                                >
                                    <div className="">
                                        <TrashIcon
                                            onClick={() => {
                                                setIsArchiveModalOpened(true);
                                                setSelectedRowsAggregator([]);
                                            }}
                                            height={25}
                                            width={25}
                                            fill="red"
                                            style={{ cursor: "pointer" }}
                                            className={`${classnames(
                                                "pointer__clz mt-2 cmn_icn_BTarchived",
                                                {
                                                    "not-allowed-icon__clz":
                                                        e.selectedRow[
                                                            t("State")
                                                        ] === true,
                                                }
                                            )}`}
                                        />
                                    </div>
                                </Tooltip>
                            ) : (
                                <Tooltip
                                    title={
                                        dataArchive.includes(e.selectedRow.Id)
                                            ? ""
                                            : t("Unarchive")
                                    }
                                >
                                    <div>
                                        <EyeIcon
                                            height={25}
                                            width={25}
                                            style={{ cursor: "pointer" }}
                                            onClick={() => {
                                                activeAggregator();
                                                setNumberActiveMultiShop(
                                                    numberActiveMultiShop + 1
                                                );
                                                setNumberArchiveMultiShop(
                                                    numberArchiveMultiShop - 1
                                                );
                                            }}
                                            className={`${classnames(
                                                "pointer__clz mt-2 mr-2 cmn_icn_BTunarchive ",
                                                {
                                                    "not-allowed-icon__clz": dataArchive.includes(
                                                        e.selectedRow.Id
                                                    ),
                                                }
                                            )}`}
                                        />
                                    </div>
                                </Tooltip>
                            )}
                        </>
                    ) : null}
                    <Tooltip title={isAggregatorArchived ? "" : t("configure")}>
                        <div>
                            <SettingsIcon
                                onClick={() => {
                                    setSelectedRows([e.selectedRow]);

                                    setIsConfigureModalOpened(true);
                                }}
                                height={25}
                                width={25}
                                style={{ marginTop: "8px" }}
                                className={`${classnames(
                                    "pointer__clz fed_icn_BTconsult",
                                    {
                                        "not-allowed-icon__clz": isAggregatorArchived,
                                    }
                                )}`}
                            />
                        </div>
                    </Tooltip>
                    <Tooltip title={t("Consult history")}>
                        <div>
                            <TimePastSvgIcon
                                className={`pointer__clz mt-2 cmn_icn_BThistory ${
                                    loading ? "not-allowed-icon__clz" : ""
                                }`}
                                onClick={() => {
                                    setIsHistoryModalOpened(true);
                                    setSelectedAggregator([
                                        {
                                            [e.selectedRow.shopId]:
                                                e.selectedRow.Id,
                                        },
                                    ]);
                                    setSelectedRows([e.selectedRow]);
                                }}
                            />
                        </div>
                    </Tooltip>
                </div>
            ) : null}
            {isArchiveModalOpened ? (
                <ConfirmationArchive
                    isModalOpened={isArchiveModalOpened}
                    setIsModalOpened={setIsArchiveModalOpened}
                    e={e}
                    setDataIsUpdated={setDataIsUpdated}
                    selectedRows={selectedRows}
                />
            ) : null}
        </React.Fragment>
    );
}
