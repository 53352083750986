import React, { useEffect } from "react";

import { useTranslation } from "react-i18next";
import {
    CrossIcon,
    StyledLabel,
    StyledTextInput,
    CheckboxIcon,
    StyledSelectInput,
} from "@aureskonnect/react-ui";
import { AvForm, AvGroup } from "availity-reactstrap-validation";
import { mutate } from "swr";
import { toast } from "react-toastify";
import classnames from "classnames";
import { useSnapshot } from "valtio";
import { diff } from "deep-object-diff";

import { store } from "@components/VerticalLayout/store";
import { generalConfigStore, setItemsDisable } from "@store";
import {
    store as saleStore,
    setNumberActive,
    setIsArchived,
    setEditedDataModeOfSale,
    setIsActionsClicked,
    setActions,
    setIsEdited,
    setIsLoading,
} from "../store";
import { clearMultiCustomSelectionData } from "@components/Common/MultiCustomSelect/store";
import { clearData } from "@components/Common/MultiCustomCreatableSelection/store";

import { ConfirmationEditMessage } from "@components/Common/ConfirmationEditMessage";
import {
    CustomCardBody,
    CustomCardFooter,
    CustomCardHeader,
} from "@components/Common/CustomCardStyled";
import MultiCustomCreatableSelection from "@components/Common/MultiCustomCreatableSelection";
import MultiCustomSelect from "@components/Common/MultiCustomSelect";

import { ConfirmationModificationMessage } from "./ConfirmationModificationMessage";
import { ConfirmationAssociationModal } from "./ConfirmationAssociationModal";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";
import AdvancedDisplayNameModal from "../../DisplayName/AdvancedDisplayNameModal";
import { languages } from "@constants/index";
import { getSalesSupportUpdated } from "@helpers/general";

import "@assets/swal.css";
import "@assets/theme.css";
import "react-toastify/dist/ReactToastify.css";
import "./index.css";

type ModeOfSaleFormType = {
    setIsAddModeOfSaleClicked: Function;
    isAddModeOfSaleClicked: boolean;
    setDataIsUpdated: React.Dispatch<React.SetStateAction<boolean | number>>;
    dataSale: React.SetStateAction<any>;
    isAddNewModeOfSaleButtonClicked: boolean;
    setDataSale: Function;
    setLocalFilterActive: Function;
    inputSaleMode: boolean;
    setInputSaleMode: Function;
    setSelectSales: Function;
    selectSales: boolean;
    selectName: boolean;
    setSelectName: Function;
    setLimit: Function;
    isConsult: boolean;
    setIsConsult: Function;
    dataState: any;
    SalesModesTypesAndTag: any;
};

export default function SaleForm({
    setIsAddModeOfSaleClicked,
    isAddModeOfSaleClicked,
    setDataIsUpdated,
    dataSale,
    isAddNewModeOfSaleButtonClicked,
    setDataSale,
    setLocalFilterActive,
    inputSaleMode,
    setInputSaleMode,
    selectSales,
    setSelectSales,
    selectName,
    setSelectName,
    setLimit,
    setIsConsult,
    isConsult,
    dataState,
    SalesModesTypesAndTag,
}: ModeOfSaleFormType) {
    const { t } = useTranslation();

    const { userID, franchiseID, shopID, oneShop, operatorID } = useSnapshot(
        store
    );
    const {
        numberActive,
        shopsModeSaleData,
        modeSaleData,
        modeOfSaleFranchise,
        dataArchive,
        dataFranchiseArchive,
        isActionsClicked,
        isEdited,
    } = useSnapshot(saleStore);
    const { itemsDisable } = useSnapshot(generalConfigStore);

    const shopId = oneShop ? shopID : 0;
    const [saleMode, setSaleMode] = React.useState<string>("");
    const [, setTag] = React.useState<string>("");
    const [title, setTitle] = React.useState<string>("");
    const [isModalOpened, setIsModalOpened] = React.useState<boolean>(false);
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const [tags, setTags] = React.useState<any[]>([]);
    const [salesMode, setSalesMode] = React.useState<any[]>([]);
    const [isNameExist, setIsNameExist] = React.useState<boolean>(false);
    const [associated, setAssociated] = React.useState<any[]>([]);
    const [isModalModifiedOpened, setIsModalModifiedOpened] = React.useState<
        boolean
    >(false);
    const [tagsData, setTagsData] = React.useState<any>([]);
    const [isSendingTag, setIsSendingTag] = React.useState<boolean>(false);
    const [displayName, setDisplayName] = React.useState<string>("");
    const [advancedDisplayName, setAdvancedDisplayName] = React.useState<any>({
        languages: {
            fr: "",
            en: "",
            nl: "",
            es: "",
            pt: "",
            it: "",
        },
    });
    const [salesSupport, setSalesSupport] = React.useState<any>([]);
    const [salesSupportData, setSalesSupportData] = React.useState<any>([]);

    const [
        isValidateButtonDisabled,
        setIsValidateButtonDisabled,
    ] = React.useState<boolean>(false);
    const [
        isAdvancedDisplayNameModalOpen,
        setIsAdvancedDisplayNameModalOpen,
    ] = React.useState(false);
    const [language, setLanguage] = React.useState<string[]>([]);
    const [saleSupportUsed, setSaleSupportUsed] = React.useState<any[]>([]);
    const [
        isModeOfSaleActiveInTemplate,
        setIsModeOfSaleActiveInTemplate,
    ] = React.useState(false);

    const translatedSaleMethod: any = {
        [t("Takeaway")]: {
            fr: "Emporter",
            en: "Takeaway",
            nl: "Mitnehmen",
            es: "Para llevar",
            pt: "Para levar",
            it: "Da asporto",
        },
        [t("In delivery")]: {
            fr: "En livraison",
            en: "In delivery",
            nl: "Lieferung",
            es: "Entrega a domicilio",
            pt: "Entrega ao domicílio",
            it: "Consegna a domicilio",
        },
        [t("Dine-in")]: {
            fr: "Sur place",
            en: "Dine-in",
            nl: "Vor-Ort-Verzehr",
            es: "Para comer en el local",
            pt: "Para comer no local",
            it: "Sul posto",
        },
        [t("Click and Collect")]: {
            fr: "Retrait C&C",
            en: "Click and Collect",
            nl: "Online-Bestellung und Abholung",
            es: "Compra y recogida en tienda",
            pt: "Compra e recolha na loja",
            it: "Clicca e ritira",
        },
        [t("Drive-through")]: {
            fr: "Drive",
            en: "Drive-through",
            nl: "Drive-through",
            es: "Drive-through",
            pt: "Drive-through",
            it: "Drive-through",
        },
        [t("Restaurant")]: {
            fr: "Restaurant",
            en: "Restaurant",
            nl: "Restaurant",
            es: "Restaurante",
            pt: "Restaurante",
            it: "Ristorante",
        },
    };

    const i18nextLng = localStorage.getItem("i18nextLng") || "fr";
    const uuidUser = localStorage.getItem("uuidUser");

    const selectedLanguage: any = languages.find(
        (el: any) => el.value === i18nextLng
    );

    // eslint-disable-next-line
    function checkIfModeOfSaleExist() {
        let isModeOfSaleExist = false;
        if (modeSaleData.data !== undefined && dataArchive !== undefined) {
            let allData: any[] = [...modeSaleData.data, ...dataArchive];
            allData.forEach((el: any) => {
                if (el["Mode of sale"] === saleMode) isModeOfSaleExist = true;
                return;
            });
        }

        return isModeOfSaleExist;
    }

    function checkIfModeOfSaleFranchiseExist() {
        let isModeOfSaleExist = false;
        if (modeOfSaleFranchise.othersData !== undefined) {
            if (
                modeOfSaleFranchise.othersData.modeOfSaleFranchise !== undefined
            ) {
                modeOfSaleFranchise.othersData.modeOfSaleFranchise.forEach(
                    (el: any) => {
                        if (el["Mode of sale"] === saleMode)
                            isModeOfSaleExist = true;
                        return;
                    }
                );
            }
        }
        return isModeOfSaleExist;
    }

    function checkIfModeOfSaleFranchiseExistAndArchived() {
        let isModeOfSaleExist = false;
        if (dataFranchiseArchive !== undefined)
            dataFranchiseArchive.forEach((el: any) => {
                if (el["Mode of sale"] === saleMode) isModeOfSaleExist = true;
                return;
            });

        return isModeOfSaleExist;
    }

    function isInclude(element: any, array: any[]) {
        return array.includes(element);
    }

    async function addModeOfSale() {
        setIsLoading(true);
        if (isValidateButtonDisabled === false) {
            setIsValidateButtonDisabled(true);
            let localData = [];
            let isModeOfSaleFranchiseExist = checkIfModeOfSaleFranchiseExist();
            let isModeOfSaleFranchiseExistAndArchived = checkIfModeOfSaleFranchiseExistAndArchived();
            const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/general/mode_of_sale`;
            let id = salesMode.find((elt: any) => elt.label === saleMode).value;
            let isFranchiseEmpty: boolean = oneShop
                ? modeOfSaleFranchise.othersData.modeOfSaleFranchise.length ===
                  0
                : modeSaleData.data.length === 0;
            let isShopEmpty: boolean = modeSaleData.data.length === 0;
            if (oneShop) {
                if (isShopEmpty === false) {
                    const itemDisable = [
                        ...JSON.parse(JSON.stringify(itemsDisable)),
                        {
                            id: id,
                            state: false,
                            shopId: shopId,
                        },
                    ];
                    setItemsDisable(itemDisable);

                    localStorage.setItem(
                        "itemsDisable",
                        JSON.stringify(itemDisable)
                    );
                }
                if (isFranchiseEmpty) {
                    let data = [
                        {
                            userId: userID,
                            shopId: [shopId, 0],
                            franchiseId: franchiseID,
                            operatorId: operatorID,
                            uuidUser: uuidUser,
                            data: {
                                [id]: {
                                    designation: saleMode,
                                    tag: tagsData,
                                    display_name: displayName,
                                    support_vente: salesSupport,
                                    isArchived: false,
                                    isFavorite: true,
                                    state: true,
                                    shopId: shopId,
                                    advancedDisplayName: advancedDisplayName,
                                },
                            },
                        },
                    ];
                    localData.push(
                        await fetch(apiUrlAdd, {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                            },
                            body: JSON.stringify(data),
                        }).then((response) => response.json())
                    );
                } else {
                    if (!isModeOfSaleFranchiseExist) {
                        let dataShop = [
                            {
                                userId: userID,
                                shopId: [shopId],
                                franchiseId: franchiseID,
                                operatorId: operatorID,
                                uuidUser: uuidUser,
                                data: {
                                    [id]: {
                                        designation: saleMode,
                                        tag: tagsData,
                                        display_name: displayName,
                                        support_vente: salesSupport,
                                        isArchived: false,
                                        isFavorite: isShopEmpty,
                                        state: isShopEmpty,
                                        shopId: shopId,
                                        advancedDisplayName: advancedDisplayName,
                                    },
                                },
                            },
                        ];
                        localData.push(
                            await fetch(apiUrlAdd, {
                                method: "POST",
                                headers: {
                                    "Content-Type": "application/json",
                                },
                                body: JSON.stringify(dataShop),
                            }).then((response) => response.json())
                        );

                        let dataFranchise = [
                            {
                                userId: userID,
                                shopId: [0],
                                franchiseId: franchiseID,
                                operatorId: operatorID,
                                uuidUser: uuidUser,
                                data: {
                                    [id]: {
                                        designation: saleMode,
                                        tag: tagsData,
                                        display_name: displayName,
                                        support_vente: salesSupport,
                                        isArchived: false,
                                        isFavorite: false,
                                        state: true,
                                        shopId: shopId,
                                        advancedDisplayName: advancedDisplayName,
                                    },
                                },
                            },
                        ];
                        localData.push(
                            await fetch(apiUrlAdd, {
                                method: "POST",
                                headers: {
                                    "Content-Type": "application/json",
                                },
                                body: JSON.stringify(dataFranchise),
                            }).then((response) => response.json())
                        );
                    }
                }
            } else {
                let data = [
                    {
                        userId: userID,
                        shopId: [0],
                        franchiseId: franchiseID,
                        operatorId: operatorID,
                        uuidUser: uuidUser,
                        data: {
                            [id]: {
                                designation: saleMode,
                                tag: tagsData,
                                display_name: displayName,
                                support_vente: salesSupport,
                                isArchived: false,
                                isFavorite: isFranchiseEmpty,
                                state: true,
                                shopId: shopId,
                                advancedDisplayName: advancedDisplayName,
                            },
                        },
                    },
                ];
                localData.push(
                    await fetch(apiUrlAdd, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify(data),
                    }).then((response) => response.json())
                );
            }
            setTags([...tags, tagsData]);
            if (isModeOfSaleFranchiseExist && oneShop) {
                setIsModalModifiedOpened(true);
                setIsArchived(false);
                setIsValidateButtonDisabled(false);
                setIsLoading(false);
            } else if (isModeOfSaleFranchiseExistAndArchived && oneShop) {
                setIsModalModifiedOpened(true);
                setIsArchived(true);
                setIsValidateButtonDisabled(false);
                setIsLoading(false);
            } else {
                try {
                    mutate(
                        apiUrlAdd,
                        Promise.all(localData).then((result: any) => {
                            if (result.error) {
                                throw Error("error");
                            }
                            toast.success(
                                `${t("Sale mode is successfully added")!}`,
                                {
                                    position: toast.POSITION.TOP_CENTER,
                                    theme: "colored",
                                    icon: (
                                        <CheckboxIcon
                                            height={25}
                                            width={25}
                                            fill="white"
                                        />
                                    ),
                                }
                            );
                            setIsSendingTag(true);
                            setDataSale([]);
                            setIsEdited(false);
                            setLocalFilterActive(false);
                            setNumberActive(numberActive + 1);
                            setTagsData([]);
                            setDataIsUpdated!(true);
                            clearMultiCustomSelectionData();
                            clearData();
                            setAdvancedDisplayName({
                                languages: {
                                    fr: "",
                                    en: "",
                                    nl: "",
                                    es: "",
                                    pt: "",
                                    it: "",
                                },
                            });
                            setIsValidateButtonDisabled(false);
                            setIsLoading(false);
                        })
                    );
                } catch (e) {
                    setIsLoading(false);
                    toast.error(`${t("There's an error")!}`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                }
            }
        }
    }

    async function editModeOfSale() {
        setIsLoading(true);
        let dataAssociate: any[] = [];
        toast.dismiss();
        if (isValidateButtonDisabled === false) {
            setIsValidateButtonDisabled(true);
            const apiUrlUpdate = `${process.env.REACT_APP_API_V2_URL}/settings/general/mode_of_sale`;
            let dataConfig = [
                {
                    userId: userID,
                    shopId: [shopId],
                    franchiseId: franchiseID,
                    operatorId: operatorID,
                    uuidUser: uuidUser,
                    data: {
                        [dataSale.Id]: {
                            designation: saleMode,
                            tag: tagsData,
                            display_name: displayName,
                            support_vente: salesSupport,
                            isArchived: dataSale.isArchived,
                            state:
                                Object.keys(dataState).length === 0
                                    ? dataSale.state
                                    : dataSale.Id === dataState.id
                                    ? dataState.state
                                    : dataSale.state,
                            isFavorite: dataSale.isFavorite,
                            shopId: shopId,
                            advancedDisplayName: advancedDisplayName,
                        },
                    },
                },
            ];

            let oldData = {
                designation: dataSale["Mode of sale"],
                tag: dataSale.tag,
                display_name: dataSale.display_name,
                support_vente: dataSale.support_vente,
                isArchived: dataSale.isArchived,
                state: dataSale.state,
                isFavorite: dataSale.isFavorite,
                shopId: shopId,
                advancedDisplayName: dataSale.advancedDisplayName,
            };

            let updatedData = getSalesSupportUpdated(
                oldData,
                dataConfig[0].data[dataSale.Id],
                "KIOSK",
                "support_vente"
            );

            setEditedDataModeOfSale(dataConfig[0]);
            const requestOptions = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Accept-Language": i18nextLng,
                },
                body: JSON.stringify({ dataConfig, updatedData }),
            };

            try {
                mutate(
                    apiUrlUpdate,
                    await fetch(apiUrlUpdate, requestOptions)
                        .then((response) => response.json())
                        .then((data) => {
                            if (data.error) {
                                throw Error("error");
                            }
                            if (!oneShop) {
                                dataAssociate = associateModeOfSale();
                            }
                            setTags([...tags, tagsData]);
                            toast.success(
                                `${t(
                                    dataAssociate.length > 0
                                        ? "Franchise sale mode has been changed successfully"
                                        : "The sales method is changed successfully"
                                )!}`,
                                {
                                    position: toast.POSITION.TOP_CENTER,
                                    theme: "colored",
                                    icon: (
                                        <CheckboxIcon
                                            height={25}
                                            width={25}
                                            fill="white"
                                        />
                                    ),
                                }
                            );

                            if (dataAssociate.length > 0) {
                                setIsModalOpen(true);
                            } else {
                                setIsSendingTag(true);
                                setIsAddModeOfSaleClicked(
                                    !isAddModeOfSaleClicked
                                );
                                setIsEdited(false);
                                clearMultiCustomSelectionData();
                                clearData();
                                setTagsData([]);
                            }

                            setDataIsUpdated!(true);
                            setIsValidateButtonDisabled(false);
                            setIsLoading(false);
                        })
                );
            } catch (e) {
                setIsLoading(false);
                toast.error(`${t("There's an error")!}`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    theme: "colored",
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
        }
    }

    const formatCreateLabel = (inputValue: any) => (
        <span>
            {inputValue} ( {t("New tag")} )
        </span>
    );

    const formatCreateLabelModeOfSale = (inputValue: any) => (
        <span>
            {inputValue} ( {t("New sales method")} )
        </span>
    );

    function associateModeOfSale() {
        let idsAssociated: any = [];
        let associated: any[] = [];
        if (shopsModeSaleData.data !== undefined) {
            shopsModeSaleData.data.forEach((element: any) => {
                element.subRows.forEach((el: any) => {
                    if (el.Id === dataSale.Id) {
                        idsAssociated.push(el.shopId);
                        associated.push(el);
                    }
                });
            });
        }
        idsAssociated = [...new Set(idsAssociated)];

        return associated;
    }

    const dataFetch = async () => {
        const data: any = await (
            await fetch(
                `${process.env.REACT_APP_API_V2_URL}/settings/general/language?userId=${userID}&shopId=${shopId}&franchiseId=${franchiseID}`,
                {
                    method: "GET",
                    headers: { "Accept-Language": i18nextLng },
                }
            )
        ).json();

        const lang: any =
            data.data !== undefined
                ? data.data.map((element: any) => {
                      return element;
                  })
                : [];
        setLanguage(lang);
    };

    const getSaleSupportUsed = React.useCallback(async () => {
        try {
            await Promise.all([
                fetch(
                    `${process.env.REACT_APP_API_V2_URL}/settings/general/isModeOfSaleUsed?userId=${userID}&shopId=${shopId}&franchiseId=${franchiseID}&modeOfSale=${dataSale.Id}`
                ).then((value) => value.json()),
                fetch(
                    `${process.env.REACT_APP_API_V2_URL}/settings/consumedSettingGeneral?shopId=${shopId}&franchiseId=${franchiseID}&type=salesMethods`
                ).then((value) => value.json()),
            ]).then((result: any) => {
                let LocalSaleSupportUsed: any[] = [];
                for (let element of result[0].supportOfSale) {
                    LocalSaleSupportUsed.push(element.label);
                }
                setSaleSupportUsed(LocalSaleSupportUsed);

                for (let element of result[1].data) {
                    let isModeOfSaleActiveInTemplate: boolean = false;
                    if (element.id === dataSale.Id) {
                        isModeOfSaleActiveInTemplate = true;
                    }

                    setIsModeOfSaleActiveInTemplate(
                        isModeOfSaleActiveInTemplate
                    );
                }
            });
        } catch (error) {
            console.log(error);
        }
    }, [franchiseID, dataSale, shopId, userID]);

    function handleValidateButtonOnClickEvent() {
        if (saleMode === "") {
            setInputSaleMode(true);
        }
        if (salesSupport.length === 0) {
            setSelectSales(true);
        }
        if (advancedDisplayName.languages[i18nextLng] === "") {
            setSelectName(true);
        }
        if (dataSale.Id) {
            if (
                dataSale["Mode of sale"] === saleMode &&
                dataSale.advancedDisplayName === advancedDisplayName &&
                dataSale.tag.every((el: any) => isInclude(el, tagsData)) &&
                tagsData.every((el: any) => isInclude(el, dataSale.tag)) &&
                dataSale.support_vente.every((el: any) =>
                    isInclude(el, salesSupport)
                ) &&
                salesSupport.every((el: any) =>
                    isInclude(el, dataSale.support_vente)
                ) &&
                Object.keys(
                    diff(dataSale.advancedDisplayName, advancedDisplayName)
                ).length === 0
            ) {
                setIsModalOpened(!isModalOpened);
            } else if (
                saleMode !== "" &&
                !isNameExist &&
                advancedDisplayName.languages[i18nextLng] !== "" &&
                salesSupport !== "" &&
                salesSupport.length !== 0
            )
                editModeOfSale();
        } else if (
            saleMode !== "" &&
            !isNameExist &&
            advancedDisplayName.languages[i18nextLng] !== "" &&
            salesSupport !== "" &&
            salesSupport.length !== 0
        ) {
            addModeOfSale();
        }
    }

    function updateSalesSupportData(saleMode: string) {
        let isDeliverooUsed = false;
        let isSaleModeEditable = false;

        const shopData = !oneShop
            ? shopsModeSaleData.data
                  .filter((item: any) => item.subRows.length > 0)
                  .flatMap((item: any) => item.subRows)
            : [];

        const combinedSalesData = [
            ...modeSaleData.data,
            ...modeSaleData.othersData.dataArchive,
        ];

        const deliverooUsedInShop = shopData.find((entry: any) =>
            entry.support_vente.includes("Deliveroo")
        );

        isDeliverooUsed = combinedSalesData.some((entry: any) =>
            entry.support_vente.includes("Deliveroo")
        );

        if (dataSale.Id !== undefined) {
            isSaleModeEditable =
                !salesSupport.includes("Deliveroo") &&
                dataSale.support_vente.includes("Deliveroo");
        }

        const saleSupportList = SalesModesTypesAndTag.ListSaleSupport;

        if (saleMode === "") return;

        const shouldIncludeDeliveroo = !isDeliverooUsed || isSaleModeEditable;
        const isSaleModeValid = [t("Takeaway"), t("In delivery")].includes(
            saleMode
        );

        if (shouldIncludeDeliveroo && isSaleModeValid) {
            const isDeliverooModeMatch =
                deliverooUsedInShop?.["Mode of sale"] === saleMode;
            setSalesSupportData(
                isDeliverooModeMatch || deliverooUsedInShop === undefined
                    ? saleSupportList
                    : !isDeliverooModeMatch
                    ? saleSupportList.filter(
                          (element: string) => element !== "Deliveroo"
                      )
                    : saleSupportList
            );
        } else {
            const filteredData: any = saleSupportList.filter(
                (element: string) => element !== "Deliveroo"
            );

            setSalesSupportData(filteredData);
        }
    }

    let isDeliverooAvaible = {
        active: false,
        saleMode: "",
    };

    if (oneShop) {
        const combinedSalesDataShop = [
            ...modeSaleData.data,
            ...modeSaleData.othersData.dataArchive,
        ];

        const isDeliverooUsed = combinedSalesDataShop.some((entry) =>
            entry.support_vente.includes("Deliveroo")
        );

        const combinedSalesData = [
            ...modeOfSaleFranchise.othersData.dataOfFranchiseArchive,
            ...modeOfSaleFranchise.othersData.modeOfSaleFranchise,
        ];
        const deliverooEntry = combinedSalesData.find((entry) =>
            entry.support_vente.includes("Deliveroo")
        );

        const isDeliveroUsedInFranchiseWithAnySaleMode = {
            saleMode: deliverooEntry ? deliverooEntry["Mode of sale"] : "",
            isDeliverooUsed: deliverooEntry !== undefined ? true : false,
        };

        isDeliverooAvaible = {
            active:
                isDeliveroUsedInFranchiseWithAnySaleMode &&
                saleMode !== undefined &&
                oneShop &&
                isDeliveroUsedInFranchiseWithAnySaleMode.isDeliverooUsed ===
                    true &&
                saleMode !==
                    isDeliveroUsedInFranchiseWithAnySaleMode.saleMode &&
                isDeliverooUsed === false,
            saleMode: isDeliveroUsedInFranchiseWithAnySaleMode.saleMode,
        };
    }

    React.useEffect(() => {
        dataFetch();
        // eslint-disable-next-line
    }, [isAdvancedDisplayNameModalOpen]);
    useEffect(() => {
        if (dataSale.Id) {
            setTitle(
                t(isConsult ? "View a sales method" : "Modify a sales method")
            );
            setSaleMode(dataSale["Mode of sale"]);
            setTagsData(dataSale.tag);
            setDisplayName(dataSale.advancedDisplayName.languages[i18nextLng]);
            setSalesSupport(dataSale.support_vente);
            setAdvancedDisplayName(dataSale.advancedDisplayName);
        } else {
            setSaleMode("");
            setTitle(t("Add a sales method"));
            setTag("");
            setIsNameExist(false);
            setDisplayName("");
            setSalesSupport("");
            setSelectName(false);
            setInputSaleMode(false);
            setSelectSales(false);
            setTagsData([]);
            setSalesSupport([]);
            setAdvancedDisplayName({
                languages: {
                    fr: "",
                    en: "",
                    nl: "",
                    es: "",
                    pt: "",
                    it: "",
                },
            });
        }
        if (!oneShop) setAssociated(associateModeOfSale);

        // eslint-disable-next-line
    }, [dataSale, t, isAddNewModeOfSaleButtonClicked]);

    useEffect(() => {
        clearData();
        clearMultiCustomSelectionData();
    }, [isAddModeOfSaleClicked, dataSale.Id]);

    useEffect(() => {
        let isModeOfSaleExist = checkIfModeOfSaleExist();
        if (
            isModeOfSaleExist &&
            (dataSale.Id === undefined ||
                (dataSale.Id !== undefined &&
                    dataSale["Mode of sale"] !== saleMode))
        ) {
            setIsNameExist(true);
        } else {
            setIsNameExist(false);
        }
        // eslint-disable-next-line
    }, [saleMode, dataSale, checkIfModeOfSaleExist]);

    useEffect(() => {
        if (dataSale.Id === undefined) {
            setDisplayName(saleMode);
        }
    }, [saleMode, dataSale]);
    useEffect(() => {
        if (advancedDisplayName.languages[i18nextLng] !== "") {
            setSelectName(false);
        }
        // eslint-disable-next-line
    }, [advancedDisplayName, i18nextLng]);
    React.useEffect(() => {
        if (dataSale.Id !== undefined) {
            getSaleSupportUsed();
            updateSalesSupportData(dataSale["Mode of sale"]);
        }
        // eslint-disable-next-line
    }, [getSaleSupportUsed, dataSale.Id]);

    React.useEffect(() => {
        if (dataSale.Id === undefined) {
            if (saleMode !== "") {
                setSalesSupport(
                    salesSupport.filter((sale: string) => sale !== "Deliveroo")
                );
            } else if (saleMode === "") {
                setSalesSupport([]);
            }
            clearMultiCustomSelectionData();
        }
        // eslint-disable-next-line
    }, [saleMode]);
    React.useEffect(() => {
        setSalesMode(SalesModesTypesAndTag.saleMode);
        setTags(SalesModesTypesAndTag.tags);
        // eslint-disable-next-line
    }, []);

    React.useEffect(() => {
        if (dataSale.Id !== undefined) {
            updateSalesSupportData(dataSale["Mode of sale"]);
        }
        // eslint-disable-next-line
    }, [salesSupport]);

    return (
        <React.Fragment>
            <AvForm className="card">
                <CustomCardHeader
                    className="d-flex align-items-center justify-content-between"
                    style={{ maxHeight: "76px" }}
                >
                    <h5
                        className={`${classnames("pt-3", {
                            mdv_txt_FReditSalesMode: dataSale.Id !== undefined,
                            mdv_txt_FRtitleFormModeOfSale:
                                dataSale.Id === undefined,
                        })}`}
                    >
                        {title}
                    </h5>
                    <CrossIcon
                        className="mdv_icn_FRcloseForm"
                        height={13}
                        width={13}
                        onClick={() => {
                            setIsConsult(false);
                            if (isEdited === true) {
                                setIsActionsClicked(!isActionsClicked);
                                setActions(() => {
                                    setDataSale([]);
                                    setIsAddModeOfSaleClicked!(false);
                                    setIsEdited(false);
                                    clearMultiCustomSelectionData();
                                    clearData();
                                    setInputSaleMode(false);
                                    setDataIsUpdated!(true);
                                });
                            } else {
                                setDataSale([]);
                                setIsAddModeOfSaleClicked!(false);
                                setIsEdited(false);
                                clearMultiCustomSelectionData();
                                clearData();
                                setInputSaleMode(false);
                                setDataIsUpdated!(true);
                            }
                        }}
                    />
                </CustomCardHeader>
                <CustomCardBody
                    className={`pl-3 pr-5 ${isConsult ? "pe-none__clz" : ""}`}
                    style={{
                        maxHeight: "470px",
                        overflowY: " auto",
                    }}
                >
                    <AvGroup>
                        <StyledLabel
                            htmlFor="text"
                            className="required__clz mt-3"
                        >
                            {t("Sales method")}
                        </StyledLabel>

                        <StyledSelectInput
                            options={salesMode}
                            placeholder={t("Select")}
                            className={classnames("mdv_inp_FRmodeOfSale", {
                                invalid__clz: inputSaleMode || isNameExist,
                                "not-allowed-input__clz":
                                    dataSale.Id !== undefined,
                            })}
                            value={
                                saleMode === ""
                                    ? null
                                    : {
                                          label: saleMode,
                                          value: saleMode,
                                      }
                            }
                            onChange={(e: any) => {
                                setSaleMode(e === null ? "" : e.label.trim());
                                setIsEdited(true);
                                setInputSaleMode(false);
                                setIsNameExist(false);
                                setSelectName(false);
                                updateSalesSupportData(
                                    e === null ? "" : e.label.trim()
                                );

                                if (
                                    (e === null || e === undefined) &&
                                    displayName === ""
                                ) {
                                    setAdvancedDisplayName({
                                        languages: {
                                            fr: "",
                                            en: "",
                                            nl: "",
                                            es: "",
                                            pt: "",
                                            it: "",
                                        },
                                    });
                                } else if (
                                    e !== null &&
                                    translatedSaleMethod[e.label] !== undefined
                                ) {
                                    setAdvancedDisplayName({
                                        languages:
                                            translatedSaleMethod[e.label],
                                    });
                                }
                            }}
                            isClearable
                            noOptionsMessage={() => t("No options")}
                            formatCreateLabel={formatCreateLabelModeOfSale}
                            autoFocus={dataSale.Id === undefined}
                        />
                        {inputSaleMode ? (
                            <div
                                style={{
                                    width: "100%",
                                    marginTop: "0.25rem",
                                    fontSize: "80%",
                                    color: "#f46a6a",
                                }}
                            >
                                {t("Please select a sales method")}
                            </div>
                        ) : null}
                        {isNameExist ? (
                            <div
                                style={{
                                    width: "100%",
                                    marginTop: "0.25rem",
                                    fontSize: "80%",
                                    color: "#f46a6a",
                                }}
                            >
                                {t("This sales method already exists")!}
                            </div>
                        ) : null}
                    </AvGroup>
                    <AvGroup>
                        <div className="d-flex justify-content-between mb-1">
                            <StyledLabel
                                htmlFor="text"
                                className="required__clz mt-3"
                            >
                                {t("Display name")}
                            </StyledLabel>
                            <span
                                style={{
                                    color: "blue",
                                    cursor: "pointer",
                                    border: "transparent",
                                    backgroundColor: "transparent",
                                    textAlign: "center",
                                    textDecoration: "underline",
                                    font:
                                        "normal normal 600 16px/21px Segoe UI",
                                    marginTop: "15px",
                                }}
                                role="button"
                                tabIndex={0}
                                onClick={(): void => {
                                    setIsAdvancedDisplayNameModalOpen(
                                        !isAdvancedDisplayNameModalOpen
                                    );
                                }}
                                className={classnames(
                                    "cmn_btn_advencedDisplayName",
                                    {
                                        "not-allowed-icon__clz":
                                            advancedDisplayName.languages[
                                                i18nextLng
                                            ] === "" && saleMode === "",
                                    }
                                )}
                            >
                                <span>{t("Advanced")}</span>
                            </span>
                        </div>
                        <StyledTextInput
                            className={classnames("mdv_inp_FRdisplayName", {
                                input__clz: selectName,
                            })}
                            autocomplete="off"
                            name="designation"
                            onChange={(e: any) => {
                                setDisplayName(e.target.value.trim());
                                setIsEdited(true);
                                setSelectName(false);
                                if (saleMode === "" && e.target.value === "") {
                                    setAdvancedDisplayName({
                                        languages: {
                                            fr: "",
                                            en: "",
                                            nl: "",
                                            es: "",
                                            pt: "",
                                            it: "",
                                        },
                                    });
                                } else {
                                    setAdvancedDisplayName({
                                        ...advancedDisplayName,
                                        languages: {
                                            ...advancedDisplayName.languages,
                                            [selectedLanguage.value]:
                                                e.target.value,
                                        },
                                    });
                                }
                            }}
                            value={
                                advancedDisplayName.languages[i18nextLng] ?? ""
                            }
                            placeholder={t("Write")}
                            type="text"
                        />
                        <div className="d-flex flex-wrap "></div>
                        {selectName ? (
                            <div
                                style={{
                                    width: "100%",
                                    marginTop: "0.25rem",
                                    fontSize: "80%",
                                    color: "#f46a6a",
                                }}
                            >
                                {t("Please enter a display name")}
                            </div>
                        ) : null}
                    </AvGroup>
                    <AvGroup>
                        <StyledLabel
                            htmlFor="text"
                            className="required__clz mt-3"
                        >
                            {t("Sales support")}
                        </StyledLabel>
                        <MultiCustomSelect
                            onDelete={() => {
                                setIsEdited(true);
                            }}
                            result={salesSupport}
                            setResult={setSalesSupport}
                            data={salesSupportData ?? []}
                            onChange={(e: any) => {
                                setIsEdited(true);
                                setSelectSales(false);
                            }}
                            className={classnames("mdv_inp_FRsalesSupport", {
                                invalid__clz: selectSales,
                                "not-allowed-input__clz":
                                    saleMode === "" ||
                                    saleMode === null ||
                                    isNameExist,
                            })}
                            placeholder={`${t("Select")}`}
                            value={t("Choose")}
                            name="sales support"
                            usedSaleSupports={saleSupportUsed}
                            isModeOfSaleActiveInTemplate={
                                isModeOfSaleActiveInTemplate
                            }
                            isDeliverooAvaible={isDeliverooAvaible}
                        />

                        {selectSales ? (
                            <div
                                style={{
                                    width: "100%",
                                    marginTop: "0.25rem",
                                    fontSize: "80%",
                                    color: "#f46a6a",
                                }}
                            >
                                {t("Please select a sales support")}
                            </div>
                        ) : null}
                    </AvGroup>
                    <AvGroup>
                        <StyledLabel htmlFor="text" className="mt-3">
                            {t("Tag")}
                        </StyledLabel>

                        <MultiCustomCreatableSelection
                            onDelete={() => {
                                setIsEdited(true);
                            }}
                            isSendingTag={isSendingTag}
                            setResult={setTagsData}
                            result={tagsData}
                            data={tags}
                            onChange={(e: any) => {
                                setIsEdited(true);
                            }}
                            onCreateOption={(e: any) => {
                                setIsEdited(true);
                            }}
                            placeholder={`${t("Select")}`}
                            value={t("Choose")}
                            name="tag"
                            noOptionsMessage={() => t("No options")}
                            formatCreateLabel={formatCreateLabel}
                            max-width="100%"
                            className="mdv_inp_FRtags"
                        />
                        <div className="d-flex flex-wrap"></div>
                    </AvGroup>
                </CustomCardBody>
                <CustomCardFooter className="pt-1 pb-2">
                    <CustomSecondaryColorButton
                        outline
                        rounded
                        className="mr-2 mdv_btn_FRcancel cmn_btn_cancel"
                        variant="light"
                        onClick={() => {
                            if (isEdited === true) {
                                setActions(() => {
                                    setIsAddModeOfSaleClicked(
                                        !isAddModeOfSaleClicked
                                    );
                                    setDataSale([]);
                                    setIsEdited(false);
                                    setDataIsUpdated!(true);
                                    clearData();
                                    clearMultiCustomSelectionData();
                                });
                                setIsActionsClicked(!isActionsClicked);
                            } else {
                                setIsAddModeOfSaleClicked(
                                    !isAddModeOfSaleClicked
                                );
                                setDataSale([]);
                                setIsEdited(false);
                                setDataIsUpdated!(true);
                                clearData();
                                clearMultiCustomSelectionData();
                            }
                            setIsConsult(false);
                        }}
                    >
                        <span> {t("Cancel")}</span>
                    </CustomSecondaryColorButton>
                    <CustomMainColorButton
                        rounded
                        variant="primary"
                        className={`mdv_btn_FRvalidate cmn_btn_validate ${classnames(
                            { "not-allowed-input-vr__clz": isConsult }
                        )}`}
                        // disabled={isValidateButtonDisabled}
                        onClick={() => {
                            handleValidateButtonOnClickEvent();
                        }}
                    >
                        {t("Validate")}
                    </CustomMainColorButton>
                </CustomCardFooter>
            </AvForm>
            <ConfirmationEditMessage
                setDataIsUpdated={setDataIsUpdated}
                isModalOpened={isModalOpened}
                setIsModalOpened={setIsModalOpened}
                isAddClicked={isAddModeOfSaleClicked}
                setIsAddClicked={setIsAddModeOfSaleClicked}
                setEditedData={setDataSale}
                setIsEdited={setIsEdited}
            />
            {isModalOpen && (
                <ConfirmationModificationMessage
                    isModalOpened={isModalOpen}
                    setIsModalOpened={setIsModalOpen}
                    setIsEdited={setIsEdited}
                    setDataIsUpdated={setDataIsUpdated}
                    associated={associated}
                    isAddModeOfSaleClicked={isAddModeOfSaleClicked}
                    setIsAddModeOfSaleClicked={setIsAddModeOfSaleClicked}
                    salesSupport={salesSupport}
                    dataSale={dataSale}
                    setLimit={setLimit}
                />
            )}
            {isModalModifiedOpened && (
                <ConfirmationAssociationModal
                    isModalOpened={isModalModifiedOpened}
                    setIsModalOpened={setIsModalModifiedOpened}
                    setDataIsUpdated={setDataIsUpdated}
                    data={{
                        saleMode: saleMode,
                        displayName: advancedDisplayName.languages[i18nextLng],
                        salesSupport: salesSupport,
                        tag: tagsData,
                        advancedDisplayName: advancedDisplayName,
                    }}
                    setDataSale={setDataSale}
                    setTagsData={setTagsData}
                    setIsEdited={setIsEdited}
                />
            )}

            {isAdvancedDisplayNameModalOpen && (
                <AdvancedDisplayNameModal
                    isConsult={isConsult}
                    isAdvancedDisplayNameModalOpen={
                        isAdvancedDisplayNameModalOpen
                    }
                    setIsAdvancedDisplayNameModalOpen={
                        setIsAdvancedDisplayNameModalOpen
                    }
                    setAdvancedDisplayName={setAdvancedDisplayName}
                    displayName={advancedDisplayName}
                    language={language}
                    isActionsClicked={isActionsClicked}
                    setActions={setActions}
                    setIsActionsClicked={setIsActionsClicked}
                    setIsEdited={setIsEdited}
                />
            )}
        </React.Fragment>
    );
}
