import React from "react";
import classnames from "classnames";
import ReactSwitch from "react-switch";
import { uuid } from "uuidv4";
import { toast } from "react-toastify";
import {
    CheckboxIcon,
    EyeIcon,
    PencilIcon,
    TrashIcon,
} from "@aureskonnect/react-ui";
import { useSnapshot } from "valtio";
import { useTranslation } from "react-i18next";
import { mutate } from "swr";
import { Tooltip } from "@mui/material";

import { store as ModeOfInformation } from "../../../../components/VerticalLayout/store";
import store, {
    setFnDelete,
    setNumberActive,
    setNumberArchived,
    setIsDeleteCheckClick,
    setIsActionsClicked,
    setIsEdited,
    setActions,
    setIsLoading,
    setKey,
} from "../store";

import ErrorToast from "@components/Common/ErrorTost";
import TimePastSvgIcon from "@components/Common/SvgIcons/TimePastSvgIcon";
import ConsultSvgIcon from "@components/Common/SvgIcons/ConsultSvgIcon";
import { generalConfigStore, setItemsDisable } from "@store";

type CustomIconsType = {
    e: any;
    setShopId: Function;
    setRowData: Function;
    setInformation: Function;
    setIsModalOpened: Function;
    isModalOpened: boolean;
    setDataIsUpdated: Function;
    setIsHistoryModalOpened: Function;
    isHistoryModalOpened: boolean;
    setSelectedRows: Function;
    isModeOfInformationArchived: boolean;
    setIsAddModeOfInformationClicked: Function;
    setLocalFilterActive: Function;
    setIsConsult: Function;
};
export function CustomIcons({
    e,
    setShopId,
    setRowData,
    setInformation,
    setIsModalOpened,
    isModalOpened,
    setDataIsUpdated,
    setIsHistoryModalOpened,
    isHistoryModalOpened,
    setSelectedRows,
    isModeOfInformationArchived,
    setIsAddModeOfInformationClicked,
    setLocalFilterActive,
    setIsConsult,
}: CustomIconsType) {
    const { t } = useTranslation();
    const {
        numberActive,
        numberArchived,
        shopsModeInformationData,
        archivedModeInformationFranchise,
        isActionsClicked,
        isEdited,
        modify,
        isLoading,
    } = useSnapshot(store);
    const { franchiseID, oneShop, userID, operatorID, shopID } = useSnapshot(
        ModeOfInformation
    );
    const { itemsDisable } = useSnapshot(generalConfigStore);
    const [modeOfInformationState, setModeOfInformationState] = React.useState<
        boolean
    >(e.selectedRow.state);
    const shopId = oneShop ? shopID : 0;
    const i18nextLng = localStorage.getItem("i18nextLng") || "fr";
    const uuidUser = localStorage.getItem("uuidUser");

    let dataAssociate: any[] = [];
    let dataArchive: any[] = [];

    if (!oneShop) {
        dataAssociate = AssociateModeOfInformation();
        dataArchive = ArchiveModeOfInformation();
    } else {
        dataArchive = ArchiveModeOfInformationFranchise();
    }

    function AssociateModeOfInformation() {
        let idsAssociated: any = [];
        if (shopsModeInformationData.data !== undefined) {
            shopsModeInformationData.data.forEach((element: any) => {
                if (element.subRows !== undefined) {
                    element.subRows.forEach((elt: any) => {
                        idsAssociated.push(elt.Id);
                    });
                }
            });
        }
        idsAssociated = [...new Set(idsAssociated)];
        return idsAssociated;
    }

    function ArchiveModeOfInformation() {
        let idsAssociated: any = [];
        if (shopsModeInformationData.data !== undefined) {
            shopsModeInformationData.data.forEach((element: any) => {
                if (element.subRows !== undefined) {
                    element.subRows.forEach((el: any) => {
                        idsAssociated.push(el.Id);
                    });
                }
            });
        }
        idsAssociated = [...new Set(idsAssociated)];
        return idsAssociated;
    }

    function ArchiveModeOfInformationFranchise() {
        let idsAssociated: any = [];

        if (archivedModeInformationFranchise !== undefined) {
            archivedModeInformationFranchise.forEach((element: any) => {
                idsAssociated.push(element.Id);
            });
        }

        idsAssociated = [...new Set(idsAssociated)];

        return idsAssociated;
    }
    async function handleStateChange() {
        let id = e.selectedRow.Id;

        if (
            id !== undefined &&
            e.selectedRow[t("Mode of information")] !== undefined
        ) {
            let dataConfig = [
                {
                    userId: userID,
                    operatorId: operatorID,
                    uuidUser: uuidUser,
                    shopId: [e.selectedRow.shopId],
                    franchiseId: franchiseID,
                    isShop: false,
                    data: {
                        [id]: {
                            designation:
                                e.selectedRow[t("Mode of information")],
                            idMI: e.selectedRow?.idMI,
                            tag: e.selectedRow.tag,
                            supportOfSale: e.selectedRow[t("Sales support")],
                            salesSupportData: [
                                e.selectedRow[t("Sales support")],
                            ],
                            displayName: e.selectedRow[t("Display name")],
                            modeOfSale: e.selectedRow[t("Sales method")],
                            isArchived: false,
                            state: !modeOfInformationState,
                            shopId: e.selectedRow.shopId,
                            stateShop: !modeOfInformationState,
                            advancedDisplayName:
                                e.selectedRow.advancedDisplayName,
                        },
                    },
                },
            ];

            const isStateChange = true;
            let apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/general/mode_of_Information`;
            mutate(
                apiUrl,
                await fetch(apiUrl, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ dataConfig, isStateChange }),
                    method: "POST",
                })
                    .then((response) => response.json())
                    .then((result) => {
                        if (result.error) {
                            throw Error(result.message);
                        }

                        toast.success(
                            modeOfInformationState === true
                                ? `${t(
                                      "The Mode of information has been successfully deactivated"
                                  )}`
                                : `${t(
                                      "The Mode of information has been successfully activated"
                                  )}`,
                            {
                                position: toast.POSITION.TOP_CENTER,
                                autoClose: 2000,
                                theme: "colored",
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                icon: (
                                    <CheckboxIcon
                                        height={25}
                                        width={25}
                                        fill="white"
                                    />
                                ),
                            }
                        );
                        setDataIsUpdated!(true);
                        if (
                            !modeOfInformationState === false &&
                            itemsDisable.find(
                                (item: any) =>
                                    item.id === e.selectedRow.idMI &&
                                    item.shopId === e.selectedRow.shopId
                            ) === undefined
                        ) {
                            const item = [
                                ...JSON.parse(JSON.stringify(itemsDisable)),
                                {
                                    id: e.selectedRow.idMI,
                                    state: !modeOfInformationState,
                                    shopId: e.selectedRow.shopId,
                                },
                            ];
                            setItemsDisable(item);
                            localStorage.setItem(
                                "itemsDisable",
                                JSON.stringify(item)
                            );
                        } else if (!modeOfInformationState === true) {
                            const item = itemsDisable.filter(
                                (item: any) =>
                                    !(
                                        item.id === e.selectedRow.idMI &&
                                        item.shopId === e.selectedRow.shopId
                                    )
                            );
                            setItemsDisable(item);
                            localStorage.setItem(
                                "itemsDisable",
                                JSON.stringify(item)
                            );
                        }
                    })
                    .catch((error: any) => {
                        toast.error(`${t("There's an error")!}`, {
                            position: toast.POSITION.TOP_CENTER,
                            autoClose: 2000,
                            theme: "colored",
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                        });
                    })
            );
        }
    }

    async function activeModeOfInformation() {
        toast.dismiss();
        if (e.selectedRow.Id !== undefined) {
            let dataModeOfInformation = [
                {
                    userId: userID,
                    operatorId: operatorID,
                    uuidUser: uuidUser,
                    shopId: [e.selectedRow.shopId],
                    franchiseId: franchiseID,
                    isShop: false,
                    data: {
                        [e.selectedRow.Id]: {
                            designation:
                                e.selectedRow[t("Mode of information")],
                            idMI: e.selectedRow?.idMI,
                            tag: e.selectedRow.tag,
                            supportOfSale: e.selectedRow[t("Sales support")],
                            salesSupportData: [
                                e.selectedRow[t("Sales support")],
                            ],
                            displayName: e.selectedRow[t("Display name")],
                            modeOfSale: e.selectedRow[t("Sales method")],
                            isArchived: false,
                            state: e.selectedRow.state,
                            shopId: e.selectedRow.shopId,
                            stateShop: true,
                            advancedDisplayName:
                                e.selectedRow.advancedDisplayName,
                        },
                    },
                },
            ];

            const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/general/mode_of_Information`;
            const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(dataModeOfInformation),
            };
            try {
                mutate(
                    apiUrlAdd,
                    await fetch(apiUrlAdd, requestOptions)
                        .then((response) => response.json())
                        .then((data) => {
                            if (data.error) {
                                throw Error("error");
                            }
                            toast.success(
                                `${t(
                                    "Information mode has been successfully unarchived"
                                )!}`,
                                {
                                    position: toast.POSITION.TOP_CENTER,
                                    theme: "colored",
                                    icon: (
                                        <CheckboxIcon
                                            height={25}
                                            width={25}
                                            fill="white"
                                        />
                                    ),
                                }
                            );
                            setIsLoading(false);
                            setKey(uuid());
                            setDataIsUpdated!(true);
                            if (
                                e.selectedRow.shopId !== "0" &&
                                e.selectedRow.state === false &&
                                itemsDisable.find(
                                    (item: any) =>
                                        item.id === e.selectedRow.idMI &&
                                        item.shopId === e.selectedRow.shopId
                                ) === undefined
                            ) {
                                const item = [
                                    ...JSON.parse(JSON.stringify(itemsDisable)),
                                    {
                                        id: e.selectedRow.idMI,
                                        state: false,
                                        shopId: e.selectedRow.shopId,
                                    },
                                ];
                                setItemsDisable(item);
                                localStorage.setItem(
                                    "itemsDisable",
                                    JSON.stringify(item)
                                );
                            }
                        })
                );
            } catch (e: any) {
                toast.error(`${t("There's an error")!}`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    theme: "colored",
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
                setIsLoading(false);
            }
        }
    }

    async function archiveModeOfInformation() {
        setIsLoading(true);
        toast.dismiss();
        if (e.selectedRow.Id !== undefined) {
            let dataModeOfInformation = [
                {
                    userId: userID,
                    operatorId: operatorID,
                    uuidUser: uuidUser,
                    shopId: [e.selectedRow.shopId],
                    franchiseId: franchiseID,
                    isShop: false,
                    data: {
                        [e.selectedRow.Id]: {
                            designation:
                                e.selectedRow[t("Mode of information")],
                            idMI: e.selectedRow.idMI,
                            tag: e.selectedRow.tag,
                            supportOfSale: e.selectedRow[t("Sales support")],
                            salesSupportData: [
                                e.selectedRow[t("Sales support")],
                            ],
                            displayName: e.selectedRow[t("Display name")],
                            modeOfSale: e.selectedRow[t("Sales method")],
                            isArchived: true,
                            state: false,
                            shopId: e.selectedRow.shopId,
                            stateShop: true,
                            advancedDisplayName:
                                e.selectedRow.advancedDisplayName,
                        },
                    },
                },
            ];

            const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/general/mode_of_Information`;
            const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(dataModeOfInformation),
            };
            try {
                mutate(
                    apiUrlAdd,
                    await fetch(apiUrlAdd, requestOptions)
                        .then((response) => response.json())
                        .then((data) => {
                            if (data.error) {
                                throw Error("error");
                            }
                            toast.success(
                                `${t(
                                    "The Information mode has been successfully archived"
                                )!}`,
                                {
                                    position: toast.POSITION.TOP_CENTER,
                                    theme: "colored",
                                    icon: (
                                        <CheckboxIcon
                                            height={25}
                                            width={25}
                                            fill="white"
                                        />
                                    ),
                                }
                            );
                            setIsLoading(false);
                            setKey(uuid());
                            setNumberActive(numberActive - 1);
                            setNumberArchived(numberArchived + 1);
                            if (e.selectedRow.shopId !== "0") {
                                const item = itemsDisable.find(
                                    (item: any) =>
                                        item.id === e.selectedRow.idMI &&
                                        item.shopId === e.selectedRow.shopId
                                );
                                if (item !== undefined) {
                                    const item = itemsDisable.filter(
                                        (item: any) =>
                                            !(
                                                item.id ===
                                                    e.selectedRow.idMI &&
                                                item.shopId ===
                                                    e.selectedRow.shopId
                                            )
                                    );
                                    setItemsDisable(item);
                                    localStorage.setItem(
                                        "itemsDisable",
                                        JSON.stringify(item)
                                    );
                                }
                            }
                        })
                );
            } catch (e) {
                toast.error(`${t("There's an error")!}`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    theme: "colored",
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
                setIsLoading(false);
            }
        }
    }

    async function isModeOfSaleArchived() {
        toast.dismiss();
        let isModeOfSaleArchived = false;
        const url = `${process.env.REACT_APP_API_V2_URL}/settings/general/mode_of_sale_archived?userId=${userID}&shopId=${shopId}&franchiseId=${franchiseID}`;
        mutate(
            url,
            await fetch(url, {
                method: "GET",
                headers: { "Accept-Language": i18nextLng },
            })
                .then((response) => response.json())
                .then((data) => {
                    data.data.forEach((elt: any) => {
                        if (
                            e.selectedRow[t("Sales method")].some(
                                (element: any) =>
                                    element.label === elt["Mode of sale"]
                            )
                        ) {
                            isModeOfSaleArchived = true;
                        }
                    });
                })
                .catch((error) => {
                    toast.error(`${t("There's an error")!}`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                })
        );
        return isModeOfSaleArchived;
    }

    async function isInformationModeConsumed() {
        toast.dismiss();
        let isInformationModeUsed = false;
        const url = `${process.env.REACT_APP_API_V2_URL}/settings/consumedSettingGeneral?shopId=${e.selectedRow.shopId}&franchiseId=${franchiseID}&type=informationModes`;
        mutate(
            url,
            await fetch(url, {
                method: "GET",
                headers: { "Content-Type": "application/json" },
            })
                .then((response) => response.json())
                .then((result) => {
                    if (
                        result.data.some(
                            (el: any) => el.id === e.selectedRow.idMI
                        )
                    ) {
                        isInformationModeUsed = true;
                    }
                })
                .catch((error) => {
                    toast.error(`${t("There's an error")!}`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                })
        );
        return isInformationModeUsed;
    }

    return (
        <React.Fragment>
            {e.selectedRow?.subRows === undefined ? (
                <div
                    className="d-flex justify-content-around"
                    style={{ zIndex: 100 }}
                >
                    {(modify === false && !isModeOfInformationArchived) ||
                    isModeOfInformationArchived ? (
                        <Tooltip title={t("To consult")}>
                            <div>
                                {" "}
                                <ConsultSvgIcon
                                    className="pointer__clz afa_icn_toConsult"
                                    height={35}
                                    width={35}
                                    style={{
                                        cursor: "pointer",
                                        position: "relative",
                                        bottom: "5px",
                                    }}
                                    fill="black"
                                    onClick={() => {
                                        if (isEdited === true) {
                                            setIsActionsClicked(
                                                !isActionsClicked
                                            );
                                            setActions(() => {
                                                setIsEdited(false);
                                                setIsAddModeOfInformationClicked(
                                                    true
                                                );
                                                setRowData(e.selectedRow);
                                                setShopId(e.selectedRow.shopId);
                                                setInformation(e.selectedRow);
                                                setLocalFilterActive(false);
                                            });
                                        } else {
                                            setIsAddModeOfInformationClicked(
                                                true
                                            );
                                            setRowData(e.selectedRow);
                                            setShopId(e.selectedRow.shopId);
                                            setInformation(e.selectedRow);
                                            setLocalFilterActive(false);
                                        }
                                        setIsConsult(true);
                                    }}
                                />
                            </div>
                        </Tooltip>
                    ) : null}
                    {modify === true ? (
                        <>
                            <Tooltip
                                title={
                                    isModeOfInformationArchived ? "" : t("Edit")
                                }
                            >
                                <div>
                                    <PencilIcon
                                        onClick={() => {
                                            if (isEdited === true) {
                                                setIsActionsClicked(
                                                    !isActionsClicked
                                                );
                                                setActions(() => {
                                                    setIsEdited(false);
                                                    setIsAddModeOfInformationClicked(
                                                        true
                                                    );
                                                    setRowData(e.selectedRow);
                                                    setShopId(
                                                        e.selectedRow.shopId
                                                    );
                                                    setInformation(
                                                        e.selectedRow
                                                    );
                                                    setLocalFilterActive(false);
                                                });
                                            } else {
                                                setIsAddModeOfInformationClicked(
                                                    true
                                                );
                                                setRowData(e.selectedRow);
                                                setShopId(e.selectedRow.shopId);
                                                setInformation(e.selectedRow);
                                                setLocalFilterActive(false);
                                            }
                                        }}
                                        height={25}
                                        width={25}
                                        style={{ cursor: "pointer" }}
                                        className={`${classnames(
                                            "pointer__clz cmn_icn_FRedit",
                                            {
                                                "not-allowed-icon__clz":
                                                    isModeOfInformationArchived ||
                                                    e.selectedRow[
                                                        t("Mode of information")
                                                    ] === undefined,
                                            },
                                            oneShop ? "mr-2" : "ml-3"
                                        )}`}
                                    />
                                </div>
                            </Tooltip>
                            {oneShop && (
                                <Tooltip
                                    title={
                                        isModeOfInformationArchived
                                            ? ""
                                            : modeOfInformationState === false
                                            ? t("Activate")
                                            : t("Unactivate")
                                    }
                                >
                                    <div>
                                        <ReactSwitch
                                            uncheckedIcon={false}
                                            checkedIcon={false}
                                            handleDiameter={26}
                                            offColor="#f7b4b8"
                                            offHandleColor="#E30613"
                                            checked={modeOfInformationState}
                                            onChange={async () => {
                                                if (isEdited === true) {
                                                    setIsActionsClicked(
                                                        !isActionsClicked
                                                    );
                                                    setActions(() => {
                                                        setIsEdited(false);
                                                        setIsAddModeOfInformationClicked(
                                                            false
                                                        );
                                                        setModeOfInformationState(
                                                            !modeOfInformationState
                                                        );
                                                        handleStateChange();
                                                    });
                                                } else {
                                                    setModeOfInformationState(
                                                        !modeOfInformationState
                                                    );
                                                    setIsAddModeOfInformationClicked(
                                                        false
                                                    );
                                                    handleStateChange();
                                                }
                                            }}
                                            onColor="#c2eddd"
                                            onHandleColor="#34C38F"
                                            width={50}
                                            height={20}
                                            className={`${classnames(
                                                " cmn_icn_BTenableDisable pointer__clz",
                                                {
                                                    "not-allowed-icon__clz":
                                                        isModeOfInformationArchived ||
                                                        e.selectedRow[
                                                            t(
                                                                "Mode of information"
                                                            )
                                                        ] === undefined,
                                                },
                                                oneShop ? "mr-2" : "ml-3"
                                            )}`}
                                        />
                                    </div>
                                </Tooltip>
                            )}

                            {!isModeOfInformationArchived ? (
                                <Tooltip title={t("Archive")}>
                                    <div>
                                        <TrashIcon
                                            onClick={async () => {
                                                setIsLoading(true);
                                                if (isEdited === true) {
                                                    setIsLoading(false);
                                                    setIsActionsClicked(
                                                        !isActionsClicked
                                                    );
                                                    setActions(async () => {
                                                        setIsEdited(false);
                                                        setIsAddModeOfInformationClicked(
                                                            false
                                                        );
                                                        if (
                                                            dataAssociate.includes(
                                                                e.selectedRow.Id
                                                            )
                                                        ) {
                                                            ErrorToast(
                                                                `${t(
                                                                    "Please note that you cannot archive an item assigned to stores."
                                                                )!}`
                                                            );
                                                        } else {
                                                            let isConsumed = await isInformationModeConsumed();
                                                            if (
                                                                isConsumed ===
                                                                true
                                                            ) {
                                                                ErrorToast(
                                                                    t(
                                                                        "Please note, you cannot archive an element used in a template"
                                                                    )
                                                                );
                                                                setIsLoading(
                                                                    false
                                                                );
                                                            } else {
                                                                setIsDeleteCheckClick(
                                                                    true
                                                                );
                                                                setIsLoading(
                                                                    false
                                                                );
                                                                setFnDelete(
                                                                    async () => {
                                                                        archiveModeOfInformation();
                                                                    }
                                                                );
                                                            }
                                                        }
                                                    });
                                                } else {
                                                    setIsAddModeOfInformationClicked(
                                                        false
                                                    );
                                                    if (
                                                        dataAssociate.includes(
                                                            e.selectedRow.Id
                                                        )
                                                    ) {
                                                        ErrorToast(
                                                            `${t(
                                                                "Please note that you cannot archive an item assigned to stores."
                                                            )!}`
                                                        );
                                                        setIsLoading(false);
                                                    } else {
                                                        let isConsumed = await isInformationModeConsumed();
                                                        if (
                                                            isConsumed === true
                                                        ) {
                                                            ErrorToast(
                                                                t(
                                                                    "Please note, you cannot archive an element used in a template"
                                                                )
                                                            );
                                                            setIsLoading(false);
                                                        } else {
                                                            setIsLoading(false);
                                                            setIsDeleteCheckClick(
                                                                true
                                                            );
                                                            setFnDelete(
                                                                async () => {
                                                                    archiveModeOfInformation();
                                                                }
                                                            );
                                                        }
                                                    }
                                                }
                                            }}
                                            height={25}
                                            width={25}
                                            fill="red"
                                            style={{ cursor: "pointer" }}
                                            className={`${classnames(
                                                "pointer__clz",
                                                {
                                                    cmn_icn_FRarchived: !oneShop,
                                                    cmn_icn_BTarchived: oneShop,
                                                    "not-allowed-icon__clz":
                                                        isLoading ||
                                                        e.selectedRow[
                                                            t(
                                                                "Mode of information"
                                                            )
                                                        ] === undefined,
                                                },

                                                oneShop ? "mr-2" : "ml-3"
                                            )}`}
                                        />
                                    </div>
                                </Tooltip>
                            ) : (
                                <Tooltip title={t("Unarchive")}>
                                    <div>
                                        <EyeIcon
                                            height={25}
                                            width={25}
                                            style={{ cursor: "pointer" }}
                                            onClick={async () => {
                                                setIsLoading(true);
                                                let isArchived = await isModeOfSaleArchived();
                                                if (isArchived === true) {
                                                    ErrorToast(
                                                        `${t(
                                                            "Please note that  the unarchiving process could not be successfully completed. Please check the settings of the store(s) data."
                                                        )!}`
                                                    );
                                                    setIsLoading(false);
                                                } else if (
                                                    dataArchive.includes(
                                                        e.selectedRow.Id
                                                    ) &&
                                                    !oneShop
                                                ) {
                                                    setIsModalOpened(
                                                        !isModalOpened
                                                    );
                                                    setIsLoading(false);
                                                } else {
                                                    activeModeOfInformation();
                                                    setNumberActive(
                                                        numberActive + 1
                                                    );
                                                    setNumberArchived(
                                                        numberArchived - 1
                                                    );
                                                }
                                                setInformation(e.selectedRow);
                                                setSelectedRows([
                                                    e.selectedRow,
                                                ]);
                                            }}
                                            className={`${classnames(
                                                "pointer__clz cmn_icn_FRunarchive",
                                                {
                                                    "not-allowed-icon__clz":
                                                        (dataArchive.includes(
                                                            e.selectedRow.Id
                                                        ) &&
                                                            oneShop) ||
                                                        e.selectedRow[
                                                            t(
                                                                "Mode of information"
                                                            )
                                                        ] === undefined ||
                                                        isLoading,
                                                },
                                                oneShop ? "mr-2" : "ml-3"
                                            )}`}
                                        />
                                    </div>
                                </Tooltip>
                            )}
                        </>
                    ) : null}
                    <Tooltip title={t("Consult history")}>
                        <div>
                            <TimePastSvgIcon
                                onClick={() => {
                                    if (isEdited === true) {
                                        setIsActionsClicked(!isActionsClicked);
                                        setActions(() => {
                                            setIsEdited(false);
                                            setIsAddModeOfInformationClicked(
                                                false
                                            );
                                            setIsHistoryModalOpened(
                                                !isHistoryModalOpened
                                            );
                                            setRowData(e.selectedRow);
                                            setSelectedRows([e.selectedRow]);
                                        });
                                    } else {
                                        setIsAddModeOfInformationClicked(false);
                                        setIsHistoryModalOpened(
                                            !isHistoryModalOpened
                                        );
                                        setRowData(e.selectedRow);
                                        setSelectedRows([e.selectedRow]);
                                    }
                                }}
                                className={`${classnames(
                                    "pointer__clz cmn_icn_FRhistory",
                                    oneShop ? "mr-2" : "ml-3",
                                    {
                                        "not-allowed-icon__clz":
                                            e.selectedRow[
                                                t("Mode of information")
                                            ] === undefined,
                                    }
                                )}`}
                            />
                        </div>
                    </Tooltip>
                </div>
            ) : null}
        </React.Fragment>
    );
}
