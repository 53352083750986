import React from "react";
import { useTranslation } from "react-i18next";
import { StyledModal, StyledLabel, CheckboxIcon } from "@aureskonnect/react-ui";
import { mutate } from "swr";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { toast } from "react-toastify";
import { useSnapshot } from "valtio";

import { capitalize, getFidelityAndEditorPos } from "@helpers/general";
import { store } from "@components/VerticalLayout/store";

import { store as fidelity, setIsEdited } from "../store";
import { clearMultiCustomSelectionData } from "@components/Common/MultiCustomSelect/store";
import { clearData } from "@components/Common/MultiCustomCreatableSelection/store";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";

type ConfirmationAssociationModalType = {
    isModalOpened: boolean;
    setIsModalOpened: Function;
    setDataIsUpdated: Function;
    data: any;
    setDesignation: Function;
    setChoiceFidelity: Function;
    setAuthenticationMode: Function;
    setIsAddFidelityClicked: Function;
};

export function ConfirmationAssociationModal({
    isModalOpened,
    setIsModalOpened,
    setDataIsUpdated,
    data,
    setDesignation,
    setChoiceFidelity,
    setAuthenticationMode,
    setIsAddFidelityClicked,
}: ConfirmationAssociationModalType) {
    const { t } = useTranslation();
    const { isArchived } = useSnapshot(fidelity);
    const { franchiseID, userID, shopID, operatorID } = useSnapshot(store);
    const uuidUser = localStorage.getItem("uuidUser");

    async function AssociateFidelity() {
        toast.dismiss();
        let fidelity = {
            userId: userID,
            shopId: [shopID],
            franchiseId: franchiseID,
            operatorId: operatorID,
            uuidUser: uuidUser,
            data: { ...data },
            isShop: true,
        };
        const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/general/fidelity`;
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(fidelity),
        };
        try {
            mutate(
                apiUrlAdd,
                await fetch(apiUrlAdd, requestOptions)
                    .then((response) => response.json())
                    .then(async (data) => {
                        if (data.error) {
                            throw Error("error");
                        }
                        await getFidelityAndEditorPos(
                            franchiseID,
                            uuidUser as string
                        );
                        toast.success(
                            `${t(
                                "The fidelity settings has been successfully assigned"
                            )!}`,
                            {
                                position: toast.POSITION.TOP_CENTER,
                                theme: "colored",
                                icon: (
                                    <CheckboxIcon
                                        height={25}
                                        width={25}
                                        fill="white"
                                    />
                                ),
                            }
                        );
                        setDataIsUpdated(true);
                        clearMultiCustomSelectionData();
                        clearData();
                        setDesignation("");
                        setChoiceFidelity("");
                        setAuthenticationMode([]);
                        setIsAddFidelityClicked(false);
                        setIsEdited(false);
                    })
            );
        } catch (e) {
            toast.error(`${t("There's an error")!}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    }

    return (
        <StyledModal toggle={true} isOpen={isModalOpened!} centered>
            <ModalHeader
                className="text-capitalize"
                toggle={() => {
                    setIsModalOpened(!isModalOpened);
                }}
            >
                <span>{capitalize(t("Alert"))}</span>
            </ModalHeader>
            <ModalBody>
                <StyledLabel
                    className="pl-3 m-0"
                    style={{ whiteSpace: "pre-line" }}
                >
                    {!isArchived
                        ? t(
                              "The fidelity settings already exist in the franchise. Would you like to associate it with this store?"
                          )
                        : t(
                              "Attention ! the fidelity settings is already archived at the franchise level"
                          )}
                </StyledLabel>
            </ModalBody>
            <ModalFooter className="border-top-0 ">
                {!isArchived && (
                    <CustomSecondaryColorButton
                        outline
                        variant="light"
                        onClick={() => {
                            setIsModalOpened(!isModalOpened);
                        }}
                        rounded
                    >
                        {t("Cancel")}
                    </CustomSecondaryColorButton>
                )}

                <CustomMainColorButton
                    rounded
                    className="mr-2"
                    variant="primary"
                    onClick={() => {
                        if (!isArchived) {
                            AssociateFidelity();
                        }

                        setIsModalOpened(!isModalOpened);
                    }}
                >
                    {!isArchived ? t("Validate") : t("Ok")}
                </CustomMainColorButton>
            </ModalFooter>
        </StyledModal>
    );
}
