import React from "react";
import { useTranslation } from "react-i18next";
import { CheckboxIcon } from "@aureskonnect/react-ui";
import { toast } from "react-toastify";
import { mutate } from "swr";
import { useSnapshot } from "valtio";
import moment from "moment";

import { store as userStore } from "@components/VerticalLayout/store";
import {
    generalConfigStore,
    setChosenTemplate,
    setFiles,
    setInitItemsFromGeneralSetting,
    setIsInformationModesEmpty,
    setProject,
} from "@store";
import { setIsLoading } from "../../../store/project";
import { setKey } from "../store";

import { setDataIsUpdated } from "@pages/SettingGeneral/VatRate/store";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";
import { moveArrayElementToTheBegin } from "../../../helpers/general";

type CardFooterListShopType = {
    dataListShops: any;
    rowData: any;
    setIsModalAssociateStore: Function;
    isModalAssociateStore: boolean;
    setLimit: Function;
    setRowData: Function;
};

export default function CardFooterListShop({
    dataListShops,
    rowData,
    setIsModalAssociateStore,
    isModalAssociateStore,
    setRowData,
}: CardFooterListShopType) {
    const { t } = useTranslation();
    const { userID, franchiseID, operatorID } = useSnapshot(userStore);
    const { project, itemsDisable } = useSnapshot(generalConfigStore);
    const [
        isValidateButtonDisabled,
        setIsValidateButtonDisabled,
    ] = React.useState<boolean>(false);
    const uuidUser = localStorage.getItem("uuidUser");

    const getProjectData = async () => {
        let dataProject = {};
        let apiUrl = `${
            process.env.REACT_APP_API_V2_URL
        }/settings/projectData?userId=106&shopId=${
            rowData.shopId
        }&franchiseId=${franchiseID}&projectId=${
            rowData.id_project ?? rowData.projectId
        }`;
        try {
            mutate(
                apiUrl,
                await fetch(apiUrl, {
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bareer ${localStorage.getItem("jwt")}`,
                    },
                    method: "GET",
                })
                    .then((response) => response.json())
                    .then((data) => {
                        dataProject = data[0].template;
                        localStorage.setItem(
                            "project",
                            JSON.stringify({
                                ...project,
                                template: data[0].template,
                                files: data[0].files,
                            })
                        );
                    })
            );
            return dataProject;
        } catch (e: any) {}
    };

    let newProject = { ...project };

    async function handleValidateButtonClickEvent(data: any) {
        let apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/projects/associate/shops`;
        let succes: boolean = false;
        let error: boolean = false;
        let savedData = {
            userId: userID,
            franchiseId: franchiseID,
            project_id: "PROJECT",
            projectId: rowData.projectId,
            operatorId: operatorID,
            uuidUser: uuidUser,
            modified_at: `${moment().format("DD/MM/YYYY [at] h:mm a")}`,
            data: data,
        };
        let shopList = Object.keys(data);
        try {
            mutate(
                apiUrl,
                await fetch(apiUrl, {
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bareer ${localStorage.getItem("jwt")}`,
                    },
                    body: JSON.stringify(savedData),
                    method: "POST",
                })
                    .then((response) => response.json())
                    .then(async (data) => {
                        if (data.error) {
                            throw Error("Error while saving selection!");
                        } else {
                            for (let el of shopList) {
                                const getSalesMethodUrl: Response = await fetch(
                                    `${process.env.REACT_APP_API_V2_URL}/settings/consomation/sale?franchiseId=${franchiseID}&shopId=${el}`
                                );
                                const saleMethods = await getSalesMethodUrl.json();
                                const getLanguagesUrl: Response = await fetch(
                                    `${process.env.REACT_APP_API_V2_URL}/settings/consomation/langue?franchiseId=${franchiseID}&shopId=${el}`,
                                    {
                                        method: "GET",
                                    }
                                );
                                const languages = await getLanguagesUrl.json();

                                if (
                                    saleMethods.filter(
                                        (item: any) =>
                                            item.active === true &&
                                            item.id !==
                                                "f25e7c96-b5d3-4f2e-acd0-1500258283c2"
                                    ).length === 0 ||
                                    languages.filter(
                                        (item: any) => item.active === true
                                    ).length === 0
                                ) {
                                    error = true;
                                } else {
                                    succes = true;
                                }
                            }
                            if (error === true) {
                                toast.warning(
                                    `${t(
                                        `Please note that the assignment could not be made to one or more stores. Please check the store(s) data settings.`
                                    )!}`,
                                    {
                                        position: toast.POSITION.TOP_CENTER,
                                        autoClose: 2000,
                                        theme: "colored",
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        icon: (
                                            <CheckboxIcon
                                                height={25}
                                                width={25}
                                                fill="white"
                                            />
                                        ),
                                    }
                                );
                            }
                            if (succes === true) {
                                toast.success(
                                    `${t("Store assignment was successful")!}`,
                                    {
                                        position: toast.POSITION.TOP_CENTER,
                                        autoClose: 2000,
                                        theme: "colored",
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        icon: (
                                            <CheckboxIcon
                                                height={25}
                                                width={25}
                                                fill="white"
                                            />
                                        ),
                                    }
                                );
                            }
                            setKey(Math.floor(Math.random() * 10000));
                            setIsLoading(false);
                        }
                    })
            );
        } catch (e: any) {
            toast.error(`${t("Mistake ! Please try again")}!`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    }

    async function handleSaveProjectsButtonOnClickEvent(
        localData: any,
        apiUrl: any
    ) {
        try {
            mutate(
                apiUrl,
                await Promise.all(localData).then(async (result: any) => {
                    let errors: boolean[] = result.map((el: any) => el.error);
                    if (!errors.includes(true)) {
                    }
                })
            );
        } catch (e: any) {
            toast.error(`${t("There's an error")}!`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    }

    async function addTemplateOfApplication() {
        let apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/application/template`;
        let shopId: any;
        let savedData: any = {
            userId: userID,
            franchiseId: franchiseID,
            appId: "KIOSK",
            shopId: [],
            data: [rowData.projectId],
            operatorId: operatorID,
            uuidUser: uuidUser,
        };
        dataListShops.forEach(async (el: any) => {
            shopId = el.id_boutique;
            savedData.shopId.push(shopId);
        });
        try {
            mutate(
                apiUrl,
                await fetch(apiUrl, {
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bareer ${localStorage.getItem("jwt")}`,
                    },
                    body: JSON.stringify(savedData),
                    method: "POST",
                })
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.error) {
                            throw Error("Error while saving selection!");
                        }
                    })
            );
        } catch (e: any) {
            toast.error(`${t("Mistake ! Please try again")}!`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    }

    async function getDataFromGeneralSetting(
        shopId: string,
        templateProject: any
    ) {
        let template: any;
        const getLanguagesUrl: Response = await fetch(
            `${process.env.REACT_APP_API_V2_URL}/settings/consomation/langue?franchiseId=${franchiseID}&shopId=${shopId}`
        );
        const languages = await getLanguagesUrl.json();
        const getSalesMethodUrl: Response = await fetch(
            `${process.env.REACT_APP_API_V2_URL}/settings/consomation/sale?franchiseId=${franchiseID}&shopId=${shopId}`
        );
        const saleMethods = await getSalesMethodUrl.json();

        if (
            JSON.parse(
                JSON.stringify(
                    (templateProject.content
                        .salesMethods as ProjectContentItemType).items.filter(
                        (a) =>
                            (saleMethods as ProjectMainContentItemType[]).some(
                                (b) =>
                                    a.id === b.id &&
                                    itemsDisable.find(
                                        (item: any) => item.id === b.id
                                    ) === undefined
                            )
                    )
                )
            ).filter((el: any) => el.active === true).length > 0
        ) {
            JSON.parse(
                JSON.stringify(templateProject.content.salesMethods)
            ).items.forEach((element: any) => {
                saleMethods.forEach((el: any) => {
                    if (el.id === element.id) {
                        if (
                            itemsDisable.find(
                                (item: any) => item.id === el.id
                            ) === undefined &&
                            el.active !== element.active
                        ) {
                            el.active = element.active;
                            el.languages = element.languages;
                        } else {
                            el.languages = element.languages;
                        }
                    }
                });
            });
        }

        if (
            JSON.parse(
                JSON.stringify(
                    templateProject.content.languages.items.filter((a: any) =>
                        languages.some(
                            (b: any) =>
                                a.id === b.id &&
                                itemsDisable.find(
                                    (item: any) => item.id === b.id
                                ) === undefined
                        )
                    )
                )
            ).filter((el: any) => el.active === true).length > 0
        ) {
            JSON.parse(
                JSON.stringify(templateProject.content.languages)
            ).items.forEach((element: any) => {
                languages.forEach((el: any) => {
                    if (el.id === element.id) {
                        if (
                            itemsDisable.find(
                                (item: any) => item.id === el.id
                            ) === undefined &&
                            el.active !== element.active
                        ) {
                            el.active = element.active;
                        }
                    }
                });
            });
        }

        if (
            saleMethods.filter(
                (item: any) =>
                    item.active === true &&
                    item.id !== "f25e7c96-b5d3-4f2e-acd0-1500258283c2"
            ).length === 0 ||
            languages.filter((item: any) => item.active === true).length === 0
        ) {
            return;
        } else {
            const getReglementUrl: Response = await fetch(
                `${process.env.REACT_APP_API_V2_URL}/settings/consomation/reglement?franchiseId=${franchiseID}&shopId=${shopId}`
            );
            const reglements = await getReglementUrl.json();

            const getInformationMethodUrl: Response = await fetch(
                `${process.env.REACT_APP_API_V2_URL}/settings/consomation/information?franchiseId=${franchiseID}&shopId=${shopId}`
            );

            const informationModes = await getInformationMethodUrl.json();
            const getLogoUrl: Response = await fetch(
                `${process.env.REACT_APP_API_V2_URL}/settings/consomation/logo?franchiseId=${franchiseID}&shopId=${shopId}`
            );
            const logo = await getLogoUrl.json();

            JSON.parse(
                JSON.stringify(templateProject.content.informationModes)
            ).items.forEach((element: any) => {
                informationModes.forEach((el: any) => {
                    if (el.id === element.id) {
                        if (
                            itemsDisable.find(
                                (item: any) => item.id === el.id
                            ) === undefined &&
                            el.active !== element.active
                        ) {
                            el.active = element.active;
                            el.languages = element.languages;
                        } else {
                            el.languages = element.languages;
                        }
                    }
                });
            });

            if (
                JSON.parse(
                    JSON.stringify(
                        (templateProject.content
                            .meansOfPayment as ProjectContentItemType).items.filter(
                            (a) =>
                                (reglements as ProjectMainContentItemType[]).some(
                                    (b) =>
                                        a.id === b.id &&
                                        itemsDisable.find(
                                            (item: any) => item.id === b.id
                                        ) === undefined
                                )
                        )
                    )
                ).filter((el: any) => el.active === true).length > 0
            ) {
                JSON.parse(
                    JSON.stringify(templateProject.content.meansOfPayment)
                ).items.forEach((element: any) => {
                    reglements.forEach((el: any) => {
                        if (el.id === element.id) {
                            if (
                                itemsDisable.find(
                                    (item: any) => item.id === el.id
                                ) === undefined &&
                                el.active !== element.active
                            ) {
                                el.active = element.active;
                                el.languages = element.languages;
                            } else {
                                el.languages = element.languages;
                            }
                        }
                    });
                });
            }

            if (
                informationModes.length === 0 ||
                informationModes.filter((item: ProjectMainContentItemType) => {
                    return saleMethods.filter((saleMethod: any) => {
                        return (
                            Object.keys(item.active).includes(
                                saleMethod.name
                            ) && saleMethod.active === true
                        );
                    });
                }).length === 0
            ) {
                setIsInformationModesEmpty(true);
            } else {
                setIsInformationModesEmpty(false);
            }

            template = {
                ...project.template,
                content: {
                    ...project.template.content,
                    languages: {
                        ...project.template.content.languages,
                        items:
                            languages.filter(
                                // eslint-disable-next-line no-loop-func
                                (language: LanguagesItemType) =>
                                    language.active === true &&
                                    language.isDefault === false
                            ).length > 1
                                ? moveArrayElementToTheBegin(
                                      languages.find(
                                          (language: LanguagesItemType) =>
                                              language.isDefault === true
                                      ),
                                      moveArrayElementToTheBegin(
                                          languages.find(
                                              // eslint-disable-next-line no-loop-func
                                              (language: LanguagesItemType) =>
                                                  language.active === true &&
                                                  language.isDefault === false
                                          ),
                                          languages
                                      )
                                  )
                                : languages,
                    },
                    salesMethods: {
                        items: saleMethods,
                    },
                    meansOfPayment: {
                        items: reglements,
                    },
                    informationModes: {
                        items: informationModes,
                    },
                    generalDesign: {
                        ...project.template.content.generalDesign,
                        logo,
                    },
                },
            };
            let files = rowData.files;
            const indexImage = rowData.files.findIndex(
                (element: any) => element.id === logo.id
            );
            if (indexImage === -1) {
                const dataLogo = {
                    id: logo.id,
                    key: "Logo",
                    name: logo.name,
                    content: logo.content,
                    defaultImage: logo.content,
                };
                files.push(dataLogo);
            }
            setProject({
                ...rowData,
                template,
                files,
                modified_at: `${moment().format("DD/MM/YYYY [at] h:mm a")}`,
            });
            setInitItemsFromGeneralSetting({
                informationModes,
                saleMethods,
                reglements,
                languages,
            });
            setChosenTemplate(template, files);
            setFiles(files);
            let project1 = { ...rowData, template, files };
            return project1;
        }
    }

    async function handleValidate() {
        setIsLoading(true);
        let template: any = await getProjectData();
        if (template.id !== "") {
            let data: any = {};
            const dataItems: Record<string, any> = {};
            let localData: any = [];
            dataItems[rowData.projectId] = {
                ...newProject,
                template: { ...template },
                projectId: rowData.projectId,
                note: rowData[t("Remark")],
                designation: rowData[t("Designation")],
                modified_at: `${moment().format("DD/MM/YYYY [at] h:mm a")}`,
            };
            let apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/project`;
            let isEmpty = false;
            for (let el of dataListShops) {
                let shopProject: any = await getDataFromGeneralSetting(
                    el.id_boutique,
                    template
                );
                let isShopAssociated = rowData.subRows.find(
                    (elt: any) => elt.id_boutique === el.id_boutique
                );
                if (
                    shopProject !== undefined &&
                    isShopAssociated === undefined
                ) {
                    data[el.id_boutique] = {
                        designation: el[t("Shop")],
                        id_boutique: el.id_boutique,
                        template: shopProject.template,
                        files: shopProject.files,
                        hoursOfUse: "",
                    };
                    let savedData: any = {
                        userId: userID,
                        shopId: el.id_boutique,
                        franchiseId: franchiseID,
                        type: "Add",
                        operatorId: operatorID,
                        uuidUser: uuidUser,
                        modified_at: `${moment().format(
                            "DD/MM/YYYY [at] h:mm a"
                        )}`,
                        data: {
                            PROJECT: {
                                id_project: "PROJECT",
                                data_project: {
                                    [rowData.projectId]: {
                                        ...shopProject,
                                        projectId: rowData.projectId,
                                        note: rowData[t("Remark")],
                                        designation: rowData[t("Designation")],
                                        status: rowData["Status"],
                                    },
                                },
                            },
                        },
                    };
                    localData.push(
                        fetch(apiUrl, {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                                authorization: `Bareer ${localStorage.getItem(
                                    "jwt"
                                )}`,
                            },
                            body: JSON.stringify(savedData),
                        }).then((response) => response.json())
                    );
                } else if (shopProject === undefined) {
                    isEmpty = true;
                }
            }

            if (Object.keys(data).length !== 0) {
                handleValidateButtonClickEvent(data);
                handleSaveProjectsButtonOnClickEvent(localData, apiUrl);
                addTemplateOfApplication();
            } else if (
                dataListShops.some(
                    (shop: any) =>
                        rowData.subRows.find(
                            (elt: any) => elt.id_boutique === shop.id_boutique
                        ) !== undefined
                )
            ) {
                toast.success(`${t("Store assignment was successful")!}`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    theme: "colored",
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    icon: <CheckboxIcon height={25} width={25} fill="white" />,
                });
                setIsLoading(false);
            }
            if (isEmpty === true) {
                toast.warning(
                    `${t(
                        `Please note that the assignment could not be made to one or more stores. Please check the store(s) data settings.`
                    )!}`,
                    {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        icon: (
                            <CheckboxIcon height={25} width={25} fill="white" />
                        ),
                    }
                );
                setIsLoading(false);
            }
            setIsModalAssociateStore(!isModalAssociateStore);
            setDataIsUpdated(true);
            setRowData([]);
        }
        if (template.id === "") {
            let apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/project`;
            let data: any = {};
            let listShops: string[] = [];
            const dataItems: Record<string, any> = {};
            let localData: any = [];
            dataListShops.forEach((element: any) => {
                listShops.push(element.id_boutique);
            });
            dataItems[rowData.projectId] = {
                ...newProject,
                template: { ...template },
                projectId: rowData.projectId,
                note: rowData[t("Remark")],
                designation: rowData[t("Designation")],
                modified_at: `${moment().format("DD/MM/YYYY [at] h:mm a")}`,
            };
            let isEmpty = false;
            for (let el of dataListShops) {
                if (
                    rowData.subRows.find(
                        (elt: any) => elt.id_boutique === el.id_boutique
                    ) === undefined
                ) {
                    const getSalesMethodUrl: Response = await fetch(
                        `${process.env.REACT_APP_API_V2_URL}/settings/consomation/sale?franchiseId=${franchiseID}&shopId=${el.id_boutique}`
                    );
                    const saleMethods = await getSalesMethodUrl.json();

                    const getLanguagesUrl: Response = await fetch(
                        `${process.env.REACT_APP_API_V2_URL}/settings/consomation/langue?franchiseId=${franchiseID}&shopId=${el.id_boutique}`
                    );
                    const languages = await getLanguagesUrl.json();

                    if (
                        saleMethods.filter(
                            (item: any) =>
                                item.active === true &&
                                item.id !==
                                    "f25e7c96-b5d3-4f2e-acd0-1500258283c2"
                        ).length > 0 &&
                        languages.filter((item: any) => item.active === true)
                            .length > 0
                    ) {
                        data[el.id_boutique] = {
                            designation: el.Boutique,
                            id_boutique: el.id_boutique,
                        };
                        let savedData: any = {
                            userId: userID,
                            shopId: el.id_boutique,
                            franchiseId: franchiseID,
                            operatorId: operatorID,
                            uuidUser: uuidUser,
                            type: "Add",
                            modified_at: `${moment().format(
                                "DD/MM/YYYY [at] h:mm a"
                            )}`,

                            data: {
                                PROJECT: {
                                    id_project: "PROJECT",
                                    data_project: {
                                        [rowData.projectId]: {
                                            ...newProject,
                                            template: { ...template },
                                            projectId: rowData.projectId,
                                            note: rowData[t("Remark")],
                                            designation:
                                                rowData[t("Designation")],
                                            status: rowData["Status"],
                                            modified_at: `${moment().format(
                                                "MM/DD/YY [at] HH:mm a"
                                            )}`,
                                        },
                                    },
                                },
                            },
                        };
                        localData.push(
                            fetch(apiUrl, {
                                method: "POST",
                                headers: {
                                    "Content-Type": "application/json",
                                    authorization: `Bareer ${localStorage.getItem(
                                        "jwt"
                                    )}`,
                                },
                                body: JSON.stringify(savedData),
                            }).then((response) => response.json())
                        );
                    } else {
                        isEmpty = true;
                    }
                }
            }
            if (Object.keys(data).length > 0) {
                handleValidateButtonClickEvent(data);
                handleSaveProjectsButtonOnClickEvent(localData, apiUrl);
                addTemplateOfApplication();
            } else if (
                dataListShops.some(
                    (shop: any) =>
                        rowData.subRows.find(
                            (elt: any) => elt.id_boutique === shop.id_boutique
                        ) !== undefined
                )
            ) {
                toast.success(`${t("Store assignment was successful")!}`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    theme: "colored",
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    icon: <CheckboxIcon height={25} width={25} fill="white" />,
                });
            }
            if (isEmpty === true) {
                toast.warning(
                    `${t(
                        `Please note that the assignment could not be made to one or more stores. Please check the store(s) data settings.`
                    )!}`,
                    {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        icon: (
                            <CheckboxIcon height={25} width={25} fill="white" />
                        ),
                    }
                );
            }
            setIsModalAssociateStore(!isModalAssociateStore);
            setKey(Math.floor(Math.random() * 10000));
            setIsLoading(false);
        }
    }

    return (
        <div>
            <div className="justify-content-between d-flex align-items-right  float-right p-3 mt-3">
                <CustomSecondaryColorButton
                    outline
                    rounded
                    className="mr-2 cmn_icn_closeAlert"
                    variant="light"
                    onClick={() => {
                        setIsModalAssociateStore(!isModalAssociateStore);
                        setRowData([]);
                    }}
                >
                    <span> {t("Cancel")}</span>
                </CustomSecondaryColorButton>
                <CustomMainColorButton
                    rounded
                    disabled={isValidateButtonDisabled}
                    variant="primary"
                    className="cmn_btn_validateAlert"
                    onClick={() => {
                        if (dataListShops.length !== 0) {
                            setIsValidateButtonDisabled(true);
                            handleValidate();
                        } else {
                            setIsModalAssociateStore(false);
                            setRowData([]);
                        }
                    }}
                >
                    {t("Validate")}
                </CustomMainColorButton>
            </div>
        </div>
    );
}
