import { t } from "i18next";
import React from "react";
import ReactSwitch from "react-switch";
import "@components/Common/Design/index.css";
import { Translation } from "./Translation";
import { useSnapshot } from "valtio";
import {
    generalConfigStore,
    setIsHeaderInformationMessage,
    setIsCustomerBalance,
} from "@store";
export function Header(): JSX.Element {
    const { project } = useSnapshot(generalConfigStore);

    function activeHeaderClientBenefitsSwitchOnClickEvent(
        setState: Function,
        state: boolean
    ) {
        setState(!state);
    }

    const resetInformationMessageContent = (((((project.template.pages.subSteps[
        "customerBenefit"
    ] as unknown) as CustomerBenefitPageType)
        .options as CustomerBenefitOptionsType)
        .customerBenefitHeader as CustomerBenefitHeaderType)
        .informationMessage as CustomerBalanceType).resetContent;
    const isHeaderInformationMessage = (((((project.template.pages.subSteps[
        "customerBenefit"
    ] as unknown) as CustomerBenefitPageType)
        .options as CustomerBenefitOptionsType)
        .customerBenefitHeader as CustomerBenefitHeaderType)
        .informationMessage as CustomerBalanceType).active;
    const isCustomerBalance = (((((project.template.pages.subSteps[
        "customerBenefit"
    ] as unknown) as CustomerBenefitPageType)
        .options as CustomerBenefitOptionsType)
        .customerBenefitHeader as CustomerBenefitHeaderType)
        .customerBalance as CustomerBalanceType).active;
    // const isProfileClient = ((((project.template.pages.subSteps[
    //     "customerBenefit"
    // ] as unknown) as CustomerBenefitPageType)
    //     .options as CustomerBenefitOptionsType)
    //     .customerBenefitHeader as CustomerBenefitHeaderType).customerProfile;
    // const isBenefitsCustomerAccount = ((((project.template.pages.subSteps[
    //     "customerBenefit"
    // ] as unknown) as CustomerBenefitPageType)
    //     .options as CustomerBenefitOptionsType)
    //     .customerBenefitHeader as CustomerBenefitHeaderType)
    //     .customerAccountAdvantages;

    return (
        <React.Fragment>
            <div
                className="mx-2 m-2 rounded border"
                style={{ borderColor: "#CECECE" }}
            >
                <div
                    className="text-left px-4 d-flex align-items-center cursor__clz"
                    style={{
                        backgroundColor: "#EDEDED",
                        height: "40px",
                        fontSize: "20px",
                    }}
                >
                    {t("Header")}
                </div>
                <div className="p-4 d-flex flex-column">
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        <div className="d-flex justify-content-between align-items-center">
                            <div>
                                <div>{t("Information message")} </div>
                                <div
                                    style={{
                                        color: "#B7B7B7",
                                        fontSize: "13px",
                                    }}
                                >
                                    {t(`${resetInformationMessageContent}`)}
                                </div>
                            </div>
                            <ReactSwitch
                                uncheckedIcon={false}
                                checkedIcon={false}
                                handleDiameter={26}
                                offColor="#f7b4b8"
                                offHandleColor="#E30613"
                                checked={isHeaderInformationMessage}
                                onChange={() => {
                                    activeHeaderClientBenefitsSwitchOnClickEvent(
                                        setIsHeaderInformationMessage,
                                        isHeaderInformationMessage
                                    );
                                }}
                                onColor="#c2eddd"
                                onHandleColor="#34C38F"
                                width={50}
                                height={20}
                            />
                        </div>
                    </div>
                    <div className="py-2 d-flex flex-rows justify-content-between align-items-center">
                        <div>{t("Customer balance")}</div>
                        <ReactSwitch
                            uncheckedIcon={false}
                            checkedIcon={false}
                            handleDiameter={26}
                            offColor="#f7b4b8"
                            offHandleColor="#E30613"
                            checked={isCustomerBalance}
                            onChange={() => {
                                activeHeaderClientBenefitsSwitchOnClickEvent(
                                    setIsCustomerBalance,
                                    isCustomerBalance
                                );
                            }}
                            onColor="#c2eddd"
                            onHandleColor="#34C38F"
                            width={50}
                            height={20}
                        />
                    </div>
                    {/* {fideltyType[shopId] !==GODIA_LOYALTY  && (
                        <>
                    
                    <div className="py-2 d-flex flex-rows justify-content-between align-items-center">
                        <div>{t("customer account advantage")}</div>
                        <ReactSwitch
                            uncheckedIcon={false}
                            checkedIcon={false}
                            handleDiameter={26}
                            offColor="#f7b4b8"
                            offHandleColor="#E30613"
                            checked={isBenefitsCustomerAccount}
                            onChange={() => {
                                activeHeaderClientBenefitsSwitchOnClickEvent(
                                    setIsBenefitsCustomerAccount,
                                    isBenefitsCustomerAccount
                                );
                            }}
                            onColor="#c2eddd"
                            onHandleColor="#34C38F"
                            width={50}
                            height={20}
                        />
                    </div>
                    <div className="py-2 d-flex flex-rows justify-content-between align-items-center">
                        <div>{t("Customer profile")}</div>
                        <ReactSwitch
                            uncheckedIcon={false}
                            checkedIcon={false}
                            handleDiameter={26}
                            offColor="#f7b4b8"
                            offHandleColor="#E30613"
                            checked={isProfileClient}
                            onChange={() => {
                                activeHeaderClientBenefitsSwitchOnClickEvent(
                                    setIsProfileClient,
                                    isProfileClient
                                );
                            }}
                            onColor="#c2eddd"
                            onHandleColor="#34C38F"
                            width={50}
                            height={20}
                        />
                    </div>
                    </>
                    )

                    } */}
                </div>
            </div>
            <Translation />
        </React.Fragment>
    );
}
