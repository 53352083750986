import React from "react";
import { DuplicateIcon, StyledLabel } from "@aureskonnect/react-ui";
import { AvGroup } from "availity-reactstrap-validation";
import { useSnapshot } from "valtio";
import { useTranslation } from "react-i18next";
import { Input } from "reactstrap";
import classnames from "classnames";

import store, { setIsEdited } from "../store";

type DeliverooConfigType = {
    setDeliverooIdentifier: Function;
    deliverooIdentifier: any;
    isConsult: boolean;
    shopId: any;
};
export function DeliverooConfig({
    setDeliverooIdentifier,
    deliverooIdentifier,
    isConsult,
    shopId,
}: DeliverooConfigType) {
    const { isConsultModalOpened } = useSnapshot(store);
    const { t } = useTranslation();
    const [secondPartIdentifier, setSecondPartIdentifier] = React.useState(
        deliverooIdentifier.substring(shopId.length + 1)
    );
    const handleCopyToClipboard = () => {
        const combinedValue =
            secondPartIdentifier.length === 0
                ? `${shopId}`
                : `${deliverooIdentifier}`;

        navigator.clipboard.writeText(combinedValue); // Copy to clipboard
        alert(`Copied: ${combinedValue}`);
    };

    return (
        <AvGroup className={`${isConsult ? "pe-none__clz" : ""}`}>
            <StyledLabel className="mt-3" htmlFor="name">
                {t("Deliveroo Establishment ID")}
            </StyledLabel>
            <div className="d-flex justify-content-between align-items-center">
                <Input
                    type="text"
                    id="Webhook"
                    name="Webhook"
                    value={shopId}
                    disabled
                    style={{
                        flexBasis: "20%",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                    }}
                />
                <Input
                    className={classnames("agr_inp_Webhook mr-2", {
                        "not-allowed-aggregator__clz": isConsultModalOpened,
                    })}
                    autocomplete="off"
                    id="Webhook"
                    name="Webhook"
                    placeholder={t("Write")}
                    type="text"
                    onChange={(e: any) => {
                        setIsEdited(true);
                        setSecondPartIdentifier(e.target.value);
                        setDeliverooIdentifier(
                            e.target.value === ""
                                ? `${shopId}`
                                : `${shopId}_${e.target.value}`
                        );
                    }}
                    style={{
                        flexBasis: "70%",
                    }}
                    value={secondPartIdentifier}
                />
                <button
                    className="btn d-flex align-items-center"
                    style={{
                        border: "1px solid #ced4da",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "51px",
                    }}
                    onClick={handleCopyToClipboard}
                >
                    <DuplicateIcon
                        style={{
                            height: "10%",
                            width: "100%",
                        }}
                    />
                </button>
            </div>
            <div>
                {t("This identifier must also be entered on the POS server.")}
            </div>
        </AvGroup>
    );
}
